// src/components/Navbars/AlertModal.js
import React from 'react';
import { Modal, ModalHeader, ModalBody, Table,FormGroup, Label, Input } from 'reactstrap';
import './AlertStyles.css';

const AlertModal = ({   
  isOpen,   
  toggle,   
  alerts = [],   
  isAutoModal,  
  alertsDisabled,  
  onAlertsDisabledChange  
}) => {      
  const displayAlerts = isAutoModal      
    ? alerts.filter(alert => alert.status === 0)      
    : alerts;      
      
  return (      
    <Modal      
      isOpen={isOpen}      
      toggle={toggle}      
      className="alert-modal"    
      size="xl"  
    >      
      <ModalHeader toggle={toggle} className={`${isAutoModal ? 'offline-alert' : ''} modal-header-responsive`}>      
        <div className="modal-header-content">  
          <div className="modal-title-section">  
            {isAutoModal ? (      
              <span className="text-danger">⚠️ Offline Boards Alert</span>      
            ) : (      
              `Alert Notifications (${alerts.filter(alert => alert.status === 0).length} offline)`      
            )}  
          </div>  
          <div className="alert-preferences-container">  
            <FormGroup check className="alert-preferences-toggle">  
              <Input  
                type="checkbox"  
                id="alertDisableCheckbox"  
                checked={alertsDisabled}  
                onChange={(e) => onAlertsDisabledChange(e.target.checked)}  
                className="alert-checkbox"  
              />  
              <Label check for="alertDisableCheckbox" className="alert-label">  
                Disable pop-up  
              </Label>  
            </FormGroup>  
          </div>  
        </div>  
      </ModalHeader>      
      <ModalBody>      
        {displayAlerts.length > 0 ? (      
          <div className="table-responsive">  
            <Table className="alert-table" bordered>      
              <thead>      
                <tr>      
                  <th className="board-name-column">Board Name</th>      
                  <th className="status-column">Status</th>      
                  <th className="timestamp-column">Last Updated</th>      
                </tr>      
              </thead>      
              <tbody>      
                {displayAlerts.map((alert, index) => (      
                  <tr key={index}>      
                    <td className="board-name board-name-column">{alert.board_id}</td>      
                    <td className="status-column">      
                      <span className={`status-badge ${alert.status === 1 ? 'online' : 'offline'}`}>      
                        {alert.status === 1 ? 'ONLINE' : 'OFFLINE'}      
                      </span>      
                    </td>      
                    <td className="timestamp-column">      
                      {alert.created_at !== "No record found"      
                        ? new Date(alert.created_at).toLocaleString()      
                        : "No record found"}      
                    </td>      
                  </tr>      
                ))}      
              </tbody>      
            </Table>      
          </div>  
        ) : (      
          <div className="text-center p-3">No alerts found</div>      
        )}      
      </ModalBody>      
    </Modal>      
  );      
};  
  
export default AlertModal;