import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../services/normalloader';
import TopNavDropdown from './TopDropdown';
import Moment from 'moment';
import * as apiService from '../services/apiService';
import { CardHeader, Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from 'reactstrap';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Switch from '@material-ui/core/Switch';
import classnames from 'classnames';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { FormControl } from "@material-ui/core"
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { DateTime } from 'luxon';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import GroupConfiguration from './GroupConfiguration';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.useTheme(am4themes_animated);
const $ = require('jquery');
$.DataTable = require('datatables.net');
class GroupingData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            showcontent: false,
            modal: false,
            activetab: "1",
            dataChange: false,
            buttontext: 'Add',
            from_date: "",
            to_date: '',
            group_data: [],
            group_process_data: [],
            group_subprocess_data: [],
            group_subprocess_total: [],
            groupList: [],
            subgroupList: [],
            group_Barchart_data: [],
            total_consumption: '',
            checkedBtotal: '',
            checkedAtotal: '',
            checkedCtotal: '',
            checkedDtotal: '',
            checkedA: true,
            checkedB: true,
            checkedC: true,
            checkedD: true,
            group_data1: [],
            group_data2: [],
            group_data3: [],
            group_data4: [],
            checkedAuom: 'kWh',
            checkedBuom: 'kWh',
            checkedCuom: 'kWh',
            checkedDuom: 'kWh',
            checkedBName: '',
            checkedAName: '',
            checkedCName: '',
            checkedDName: '',
            groupingData: [],
            chartInstances: {},
            sliceVisibility: {},
            chartTotals: {},
            lastUpdate: null
        };
        this.groupingData = [];
        this.selectedcustomer = localStorage.getItem('selectedcustomer');
        this.currency = localStorage.getItem('currency');
        this.selectedboard = ''
        this.customerhandler = this.customerhandler.bind(this);
        this.boardhandler = this.boardhandler.bind(this);
        this.updateQueue = new Map(); // For handling multiple rapid updates
        //const e=new Date()
        const today1 = new Date();
        const e = new Date(today1);
        //const e1=new Date(today.getDate() - 1);
        e.setDate(today1.getDate() - 1);
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();

    }

    debugSeriesState = (series, chartIndex) => {
        if (!series) {
            console.warn(`Chart ${chartIndex}: Series not available`);
            return;
        }

        try {
            console.log(`Chart ${chartIndex} Debug Info:`, {
                hasSlices: !!series.slices,
                slicesCount: series.slices ? series.slices.values.length : 0,
                sliceStates: series.slices ? series.slices.values.map(slice => ({
                    category: slice.dataItem.category,
                    visible: !slice.isHidden && slice.visible,
                    value: slice.dataItem.value
                })) : []
            });
        } catch (error) {
            console.error(`Error in debugSeriesState for chart ${chartIndex}:`, error);
        }
    };

    // Enhanced total calculation with slice visibility check  
    calculateChartTotal = (data, metric = 'kwh') => {
        try {
            if (!Array.isArray(data) || data.length === 0) {
                return 0;
            }
            return data.map(item => parseInt(item[metric] || 0)).reduce((prev, curr) => prev + curr, 0);
        } catch (error) {
            console.error('Error calculating chart total:', error);
            return 0;
        }
    };

    calculateVisibleTotal = (series, metric = 'kwh') => {
        if (!series || !series.slices) {
            console.warn('Series or slices not available');
            return 0;
        }

        try {
            // Ensure series data is validated  
            if (series.validateData) {
                series.validateData();
            }

            // Get all slices and filter visible ones  
            const visibleSlices = series.slices.values.filter(slice => {
                return slice && !(slice.isHidden || !slice.visible);
            });

            // Calculate total from visible slices and round off  
            const visibleTotal = Math.round(visibleSlices.reduce((total, slice) => {
                if (!slice.dataItem || !slice.dataItem.dataContext) {
                    return total;
                }

                const value = metric === 'kwh'
                    ? parseFloat(slice.dataItem.dataContext.kwh || 0)
                    : parseFloat(slice.dataItem.dataContext.cost || 0);

                return total + (isNaN(value) ? 0 : value);
            }, 0));

            console.log(`Chart visible total (${metric}):`, {
                totalValue: visibleTotal,
                visibleSlicesCount: visibleSlices.length,
                metric: metric
            });

            return visibleTotal;

        } catch (error) {
            console.error('Error calculating visible total:', error);
            console.error('Series state:', {
                hasSlices: !!series.slices,
                slicesCount: series.slices ? series.slices.values.length : 0,
                metric: metric
            });
            return 0;
        }
    };

    // Enhanced slice visibility handler  
    handleSliceVisibilityChange = (chartId, series, slice) => {
        // Queue update using requestAnimationFrame  
        if (this.updateQueue.has(chartId)) {
            cancelAnimationFrame(this.updateQueue.get(chartId));
        }

        const frameId = requestAnimationFrame(() => {
            try {
                // Update slice visibility state  
                this.setState(prevState => ({
                    sliceVisibility: {
                        ...prevState.sliceVisibility,
                        [chartId]: {
                            ...prevState.sliceVisibility[chartId],
                            [slice.dataItem.category]: !(slice.isHidden || !slice.visible)
                        }
                    }
                }));

                // Recalculate total after visibility change  
                const newTotal = this.calculateChartTotal(chartId, series);

                this.setState(prevState => ({
                    chartTotals: {
                        ...prevState.chartTotals,
                        [chartId]: newTotal
                    },
                    lastUpdate: new Date().getTime()
                }));

                // Detailed logging  
                console.log(`Slice visibility update for chart ${chartId}:`, {
                    slice: slice.dataItem.category,
                    visible: !(slice.isHidden || !slice.visible),
                    newTotal
                });

            } catch (error) {
                console.error(`Error handling slice visibility for chart ${chartId}:`, error);
            }
        });

        this.updateQueue.set(chartId, frameId);
    };

    barChartForm = FormBuilder.group({
        start_date: [this.dailytoday, [Validators.required]],
        end_date: [this.dailytoday, [Validators.required]],
        grouping_id: ["", [Validators.required]],
        grouping_process_id: ["", [Validators.required]],
    });
    PieChartForm = FormBuilder.group({
        start_date: [this.dailytoday, [Validators.required]],
        end_date: [this.dailytoday, [Validators.required]],

    });

    customerhandler(customerId) {
        this.selectedcustomer = customerId
        this.setState({
            showcontent: false
        });
        localStorage.setItem('selectedcustomer', customerId)
        this.toggle("1");
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        localStorage.setItem('selectedboard', board_id)
        this.setState({
            showcontent: true
        })
        this.toggle("1");
    }
    convertdate1(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.PieChartForm.patchValue({
            start_date: this.dailytoday,
            end_date: this.dailytoday,
        })
        this.barChartForm.patchValue({
            start_date: this.dailytoday,
            end_date: this.dailytoday,
        })
    }
    componentDidMount() {
        //this.customerhandler(this);
        this.toggle("1");
        //this.initresponse(this.selectedcustomer)
        const tdy = new Date();
        const ydy = new Date(tdy);
        ydy.setDate(tdy.getDate() - 1)
        this.convertdate1(ydy);
        const val = this.PieChartForm.getRawValue();
        this.getGroupingList();
        //this.GetGroupingChart(this.selectedcustomer,val.start_date,val.end_date)
    }
    handlePieChartSubmit(e, value) {
        e.preventDefault();
        this.GetGroupingChart(this.selectedcustomer, value.start_date, value.end_date);
    }
    handleBarChartSubmit(e, value) {
        e.preventDefault();
        this.GetBarChart(this.selectedcustomer, value.start_date, value.end_date, value.grouping_process_id);
    }
    GetBarChart(customer_id, from_date, to_date, grouping_process_id) {
        // debugger;
        this.setState({ loader: true })
        var param = {};
        param.customer_id = customer_id;
        param.from_date = from_date;
        param.to_date = to_date;
        param.grouping_process_id = grouping_process_id;
        apiService.GetBarGroupingChart(param).then(response => {
            this.setState({ loader: false })
            var data = response.data[0].group_subprocess_data;
            for (let i = 0; i <= data.length - 1; i++) {
                //var lastStr = data[i]['description'].split("-").splice(-1)[0];
                //var lastButStr= data[i]['description'].split("-").splice(-2)[0];
                data[i]['new_category'] = data[i]['description']
            }
            this.setState({
                group_Barchart_data: data
            });
            this.createBarchart1(data, this.props.theme);
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    GetGroupingChart(customer_id, from_date, to_date) {
        // debugger;
        this.setState({ loader: true })
        var param = {};
        param.customer_id = customer_id;
        param.from_date = from_date;
        param.to_date = to_date;
        apiService.GetGroupingChart(param).then(response => {
            //debugger;
            this.setState({ loader: false })
            if (response.data.length != 0) {
                //this.groupingData = response.data;
                this.setState({
                    group_data: response.data,
                    group_data1: response.data.length > 0 ? response.data[0].group_data : [],
                    group_data2: response.data.length > 1 ? response.data[1].group_data : [],
                    group_data3: response.data.length > 2 ? response.data[2].group_data : [],
                    group_data4: response.data.length > 3 ? response.data[3].group_data : [],
                    checkedAName: response.data.length > 0 ? response.data[0].name : "",
                    checkedBName: response.data.length > 1 ? response.data[1].name : "",
                    checkedCName: response.data.length > 2 ? response.data[2].name : "",
                    checkedDName: response.data.length > 3 ? response.data[3].name : "",
                })
                this.setState({
                    checkedAtotal: this.calculateChartTotal(response.data[0]?.group_data, 'kwh'),
                    checkedBtotal: this.calculateChartTotal(response.data[1]?.group_data, 'kwh'),
                    checkedCtotal: this.calculateChartTotal(response.data[2]?.group_data, 'kwh'),
                    checkedDtotal: this.calculateChartTotal(response.data[3]?.group_data, 'kwh'),
                });
                var tsData = response.data.map(item => ({
                    name: item.name,
                    group_data: item.group_data,
                    checkedtotal: item.group_data.map(item => parseInt(item.kwh)).reduce((prev, curr) => prev + curr, 0),
                    checked: true,
                    checkeduom: 'kWh'
                }));
                this.setState({
                    groupingData: tsData
                });
                this.groupingData = tsData;
                for (let i = 0; i < this.groupingData.length; i++) {
                    this.createchart(true, response.data[i].group_data, response.data[i].group_data, this.props.theme, i)
                }
                // this.createchart1(this.state.checkedA, response.data[0].group_data, response.data[0].group_data, this.props.theme);
                // this.createchart2(this.state.checkedB, response.data[1].group_data, response.data[1].group_data, this.props.theme);
                // this.createchart3(this.state.checkedC, response.data[2].group_data, response.data[2].group_data, this.props.theme);
                // this.createchart4(this.state.checkedD, response.data[3].group_data, response.data[3].group_data, this.props.theme);
            }
            else {
                this.groupingData = [];
                this.setState({
                    groupingData: []
                });
            }
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }
    getGroupingList() {
        apiService.getGroupingList().then(response => {
            this.setState({ groupList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    getSubGroupingList(value) {
        apiService.getddlGroupingProcessList(value).then(response => {
            this.setState({ subgroupList: response.data, loader: false })

        }, error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)

        })
    }
    toggle(option) {
        var changeToggle = true;
        if (changeToggle) {
            this.setState({
                activetab: option
            })
            if (option == "2") {
            }
            if (option == "3") {
            }
            if (option == "1") {
                const tdy = new Date();
                const ydy = new Date(tdy);
                ydy.setDate(tdy.getDate() - 1)
                this.convertdate1(ydy);
                this.GetGroupingChart(this.selectedcustomer, this.dailytoday, this.dailytoday);
            }
            if (option == "4") {
            }
        }
    }

    handleEventInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        var Formvalue = this.barChartForm.getRawValue();
        this.barChartForm.patchValue({
            start_date: Formvalue.start_date,
            end_date: Formvalue.end_date,
            grouping_id: event.target.value,
            grouping_process_id: '',
        })
        this.getSubGroupingList(event.target.value);
    };
    handlePieToggleChange(event, index) {
        this.groupingData = this.state.groupingData;
        if (event.target.checked == true) {
            this.groupingData[index].checked = event.target.checked;
            this.groupingData[index].checkeduom = 'kWh';
            this.groupingData[index].checkedtotal = this.calculateChartTotal(this.groupingData[index].group_data, 'kwh');
        } else {
            this.groupingData[index].checked = event.target.checked;
            this.groupingData[index].checkeduom = 'Cost';
            this.groupingData[index].checkedtotal = this.calculateChartTotal(this.groupingData[index].group_data, 'cost');
        }
        this.setState({
            groupingData: this.groupingData
        });
        this.createchart(event.target.checked, this.groupingData[index].group_data, this.groupingData[index].group_data, this.props.theme, index);
    }
    handleChange(event, index) {
        if (event.target.name == 'checkedA') {
            if (event.target.checked == true) {
                this.setState({ checkedAuom: 'kWh', checkedA: event.target.checked })
                this.setState({
                    checkedAtotal: this.state.group_data1.map(item => parseInt(item.kwh)).reduce((prev, curr) => prev + curr, 0),
                })

            } else {
                this.setState({ checkedAuom: 'Cost', checkedA: event.target.checked })
                this.setState({
                    checkedAtotal: this.state.group_data1.map(item => parseInt(item.cost)).reduce((prev, curr) => prev + curr, 0),
                })
            }
            this.createchart1(event.target.checked, this.state.group_data1, this.state.group_data1, this.props.theme);
        }
        if (event.target.name == 'checkedB') {
            if (event.target.checked == true) {
                this.setState({ checkedBuom: 'kWh', checkedB: event.target.checked })
                this.setState({
                    checkedBtotal: this.state.group_data2.map(item => parseInt(item.kwh)).reduce((prev, curr) => prev + curr, 0),
                })
            } else {
                this.setState({ checkedBuom: 'Cost', checkedB: event.target.checked })
                this.setState({
                    checkedBtotal: this.state.group_data2.map(item => parseInt(item.cost)).reduce((prev, curr) => prev + curr, 0),
                })
            }
            this.createchart2(event.target.checked, this.state.group_data2, this.state.group_data2, this.props.theme);
        }
        if (event.target.name == 'checkedC') {
            if (event.target.checked == true) {
                this.setState({ checkedCuom: 'kWh', checkedC: event.target.checked })
                this.setState({
                    checkedCtotal: this.state.group_data3.map(item => parseInt(item.kwh)).reduce((prev, curr) => prev + curr, 0),
                })
            } else {
                this.setState({ checkedCuom: 'Cost', checkedC: event.target.checked })
                this.setState({
                    checkedCtotal: this.state.group_data3.map(item => parseInt(item.cost)).reduce((prev, curr) => prev + curr, 0),
                })
            }
            this.createchart3(event.target.checked, this.state.group_data3, this.state.group_data3, this.props.theme);
        }
        if (event.target.name == 'checkedD') {
            if (event.target.checked == true) {
                this.setState({ checkedDuom: 'kWh', checkedD: event.target.checked })
                this.setState({
                    checkedDtotal: this.state.group_data4.map(item => parseInt(item.kwh)).reduce((prev, curr) => prev + curr, 0),
                })
            } else {
                this.setState({ checkedDuom: 'Cost', checkedD: event.target.checked })
                this.setState({
                    checkedDtotal: this.state.group_data4.map(item => parseInt(item.cost)).reduce((prev, curr) => prev + curr, 0),
                })
            }
            this.createchart4(event.target.checked, this.state.group_data4, this.state.group_data4, this.props.theme);
        }
    }
    //------------------------ Chart Configuration --------------------------------------
    createchart(checkedA, data1, data2, theme, index) {
        if (theme == 'darktheme' || theme == 'greytheme') {
            am4core.unuseTheme(am4themes_material);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_dark);
        } else {
            am4core.unuseTheme(am4themes_dark);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_material);
        }
        let chart = am4core.create("topchartdiv" + index, am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0;
        chart.innerRadius = am4core.percent(30);
        chart.logo.disabled = true;

        var series = chart.series.push(new am4charts.PieSeries3D());
        if (checkedA == true) {
            chart.data = data1
            series.dataFields.value = "kwh";
        } else {
            chart.data = data2
            series.dataFields.value = "cost";
        }

        series.dataFields.category = "process_name";
        series.ticks.template.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.maxWidth = 300;

        chart.legend.valign = "middle"
        chart.legend.scrollable = true;
        series.alignLabels = false;

        // Add slice visibility change handler  
        series.slices.template.events.on("visibilitychanged", (ev) => {
            try {
                requestAnimationFrame(() => {
                    const metric = checkedA ? 'kwh' : 'cost';
                    const newTotal = this.calculateVisibleTotal(series, metric);

                    // Debug output  
                    this.debugSeriesState(series, index);

                    // Update state with new total  
                    this.setState(prevState => {
                        const groupingData = [...prevState.groupingData];
                        if (groupingData[index]) {
                            groupingData[index] = {
                                ...groupingData[index],
                                checkedtotal: newTotal
                            };
                        }
                        return { groupingData };
                    });
                });
            } catch (error) {
                console.error('Error in visibility change handler:', error);
            }
        });

        // Add legend click handler  
        chart.legend.itemContainers.template.events.on("hit", (ev) => {
            try {
                requestAnimationFrame(() => {
                    const metric = checkedA ? 'kwh' : 'cost';
                    const newTotal = this.calculateVisibleTotal(series, metric);

                    this.setState(prevState => {
                        const groupingData = [...prevState.groupingData];
                        if (groupingData[index]) {
                            groupingData[index] = {
                                ...groupingData[index],
                                checkedtotal: newTotal
                            };
                        }
                        return { groupingData };
                    });
                });
            } catch (error) {
                console.error('Error in legend hit handler:', error);
            }
        });

        if (checkedA == true) {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        } else {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        }
        series.labels.template.radius = am4core.percent(-45);
        series.labels.template.fill = am4core.color("white");
        series.labels.template.relativeRotation = 90;
        series.hiddenState.properties.endAngle = -90;
        if (checkedA == true) {
            series.slices.template.tooltipText = "{category}: {value.value}" + " kWh";
        } else {
            series.slices.template.tooltipText = "{category}: {value.value} " + this.currency;
        }
        series.labels.template.adapter.add("radius", function (radius, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0;
            }
            return radius;
        });
        series.labels.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return am4core.color("#ffffff");
            }
            return color;
        });
        series.labels.template.adapter.add("opacity", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0
            }
            return 1;
        });
        if (theme == 'darktheme') {
            series.colors.list = [
                am4core.color("#e91e63"),
                am4core.color("#00bcd4"),
                am4core.color("#f7e53b"),
                am4core.color("#ffc107"),
                am4core.color("#2196f3"),
                am4core.color("#93c360"),
                am4core.color("#ab26c2"),
                am4core.color("#009688"),
                am4core.color("#d73e71"),
                am4core.color("#58e2c2"),
            ];
        }
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function (target) {
                if (target.pixelWidth <= 400) {
                    chart.legend.maxHeight = 150;

                    chart.legend.disabled = true;
                    return true;
                }
                if (target.pixelWidth > 400) {
                    chart.legend.maxHeight = 300;
                    chart.legend.disabled = false;
                    return false;
                }
                return false;
            },
            state: function (target, stateId) {
                if (target instanceof am4charts.PieSeries) {
                    var state = target.states.create(stateId);
                    return state;
                }
                return null;
            }
        });
        this.chart1 = chart;
    }
    createchart1(checkedA, data1, data2, theme) {
        if (theme == 'darktheme' || theme == 'greytheme') {
            am4core.unuseTheme(am4themes_material);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_dark);
        } else {
            am4core.unuseTheme(am4themes_dark);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_material);
        }
        let chart = am4core.create("topchartdiv1", am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0;
        chart.innerRadius = am4core.percent(30);
        chart.logo.disabled = true;

        var series = chart.series.push(new am4charts.PieSeries3D());
        if (checkedA == true) {
            chart.data = data1
            series.dataFields.value = "kwh";
        } else {
            chart.data = data2
            series.dataFields.value = "cost";
        }

        series.dataFields.category = "process_name";
        series.ticks.template.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.maxWidth = 300;

        chart.legend.valign = "middle"
        chart.legend.scrollable = true;
        series.alignLabels = false;
        if (checkedA == true) {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        } else {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        }
        series.labels.template.radius = am4core.percent(-45);
        series.labels.template.fill = am4core.color("white");
        series.labels.template.relativeRotation = 90;
        series.hiddenState.properties.endAngle = -90;
        if (checkedA == true) {
            series.slices.template.tooltipText = "{category}: {value.value}" + " kWh";
        } else {
            series.slices.template.tooltipText = "{category}: {value.value} " + this.currency;
        }
        series.labels.template.adapter.add("radius", function (radius, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0;
            }
            return radius;
        });
        series.labels.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return am4core.color("#ffffff");
            }
            return color;
        });
        series.labels.template.adapter.add("opacity", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0
            }
            return 1;
        });
        if (theme == 'darktheme') {
            series.colors.list = [
                am4core.color("#e91e63"),
                am4core.color("#00bcd4"),
                am4core.color("#f7e53b"),
                am4core.color("#ffc107"),
                am4core.color("#2196f3"),
                am4core.color("#93c360"),
                am4core.color("#ab26c2"),
                am4core.color("#009688"),
                am4core.color("#d73e71"),
                am4core.color("#58e2c2"),
            ];
        }
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function (target) {
                if (target.pixelWidth <= 400) {
                    chart.legend.maxHeight = 150;

                    chart.legend.disabled = true;
                    return true;
                }
                if (target.pixelWidth > 400) {
                    chart.legend.maxHeight = 300;
                    chart.legend.disabled = false;
                    return false;
                }
                return false;
            },
            state: function (target, stateId) {
                if (target instanceof am4charts.PieSeries) {
                    var state = target.states.create(stateId);
                    return state;
                }
                return null;
            }
        });
        this.chart1 = chart;
    }
    createchart2(checkedA, data1, data2, theme) {
        if (theme == 'darktheme' || theme == 'greytheme') {
            am4core.unuseTheme(am4themes_material);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_dark);
        } else {
            am4core.unuseTheme(am4themes_dark);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_material);
        }
        let chart = am4core.create("topchartdiv2", am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0;
        chart.innerRadius = am4core.percent(30);
        chart.logo.disabled = true;

        var series = chart.series.push(new am4charts.PieSeries3D());
        if (checkedA == true) {
            chart.data = data1
            series.dataFields.value = "kwh";
        } else {
            chart.data = data2
            series.dataFields.value = "cost";
        }

        series.dataFields.category = "process_name";
        series.ticks.template.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.maxWidth = 300;

        chart.legend.valign = "middle"
        chart.legend.scrollable = true;
        series.alignLabels = false;
        if (checkedA == true) {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        } else {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        }
        series.labels.template.radius = am4core.percent(-45);
        series.labels.template.fill = am4core.color("white");
        series.labels.template.relativeRotation = 90;
        series.hiddenState.properties.endAngle = -90;
        if (checkedA == true) {
            series.slices.template.tooltipText = "{category}: {value.value}" + " kWh";
        } else {
            series.slices.template.tooltipText = "{category}: {value.value} " + this.currency;
        }
        series.labels.template.adapter.add("radius", function (radius, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0;
            }
            return radius;
        });
        series.labels.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return am4core.color("#ffffff");
            }
            return color;
        });
        series.labels.template.adapter.add("opacity", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0
            }
            return 1;
        });
        if (theme == 'darktheme') {
            series.colors.list = [
                am4core.color("#e91e63"),
                am4core.color("#00bcd4"),
                am4core.color("#f7e53b"),
                am4core.color("#ffc107"),
                am4core.color("#2196f3"),
                am4core.color("#93c360"),
                am4core.color("#ab26c2"),
                am4core.color("#009688"),
                am4core.color("#d73e71"),
                am4core.color("#58e2c2"),
            ];
        }
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function (target) {
                if (target.pixelWidth <= 400) {
                    chart.legend.maxHeight = 150;

                    chart.legend.disabled = true;
                    return true;
                }
                if (target.pixelWidth > 400) {
                    chart.legend.maxHeight = 300;
                    chart.legend.disabled = false;
                    return false;
                }
                return false;
            },
            state: function (target, stateId) {
                if (target instanceof am4charts.PieSeries) {
                    var state = target.states.create(stateId);
                    return state;
                }
                return null;
            }
        });
        this.chart1 = chart;
    }
    createchart3(checkedA, data1, data2, theme) {
        if (theme == 'darktheme' || theme == 'greytheme') {
            am4core.unuseTheme(am4themes_material);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_dark);
        } else {
            am4core.unuseTheme(am4themes_dark);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_material);
        }
        let chart = am4core.create("topchartdiv3", am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0;
        chart.innerRadius = am4core.percent(30);
        chart.logo.disabled = true;

        var series = chart.series.push(new am4charts.PieSeries3D());
        if (checkedA == true) {
            chart.data = data1
            series.dataFields.value = "kwh";
        } else {
            chart.data = data2
            series.dataFields.value = "cost";
        }

        series.dataFields.category = "process_name";
        series.ticks.template.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.maxWidth = 300;

        chart.legend.valign = "middle"
        chart.legend.scrollable = true;
        series.alignLabels = false;
        if (checkedA == true) {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        } else {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        }
        series.labels.template.radius = am4core.percent(-45);
        series.labels.template.fill = am4core.color("white");
        series.labels.template.relativeRotation = 90;
        series.hiddenState.properties.endAngle = -90;
        if (checkedA == true) {
            series.slices.template.tooltipText = "{category}: {value.value}" + " kWh";
        } else {
            series.slices.template.tooltipText = "{category}: {value.value} " + this.currency;
        }
        series.labels.template.adapter.add("radius", function (radius, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0;
            }
            return radius;
        });
        series.labels.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return am4core.color("#ffffff");
            }
            return color;
        });
        series.labels.template.adapter.add("opacity", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0
            }
            return 1;
        });
        if (theme == 'darktheme') {
            series.colors.list = [
                am4core.color("#e91e63"),
                am4core.color("#00bcd4"),
                am4core.color("#f7e53b"),
                am4core.color("#ffc107"),
                am4core.color("#2196f3"),
                am4core.color("#93c360"),
                am4core.color("#ab26c2"),
                am4core.color("#009688"),
                am4core.color("#d73e71"),
                am4core.color("#58e2c2"),
            ];
        }
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function (target) {
                if (target.pixelWidth <= 400) {
                    chart.legend.maxHeight = 150;

                    chart.legend.disabled = true;
                    return true;
                }
                if (target.pixelWidth > 400) {
                    chart.legend.maxHeight = 300;
                    chart.legend.disabled = false;
                    return false;
                }
                return false;
            },
            state: function (target, stateId) {
                if (target instanceof am4charts.PieSeries) {
                    var state = target.states.create(stateId);
                    return state;
                }
                return null;
            }
        });
        this.chart1 = chart;
    }
    createchart4(checkedA, data1, data2, theme) {
        if (theme == 'darktheme' || theme == 'greytheme') {
            am4core.unuseTheme(am4themes_material);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_dark);
        } else {
            am4core.unuseTheme(am4themes_dark);
            am4core.unuseTheme(am4themes_dataviz);
            am4core.unuseTheme(am4themes_kelly);
            am4core.useTheme(am4themes_material);
        }
        let chart = am4core.create("topchartdiv4", am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 0;
        chart.innerRadius = am4core.percent(30);
        chart.logo.disabled = true;

        var series = chart.series.push(new am4charts.PieSeries3D());
        if (checkedA == true) {
            chart.data = data1
            series.dataFields.value = "kwh";
        } else {
            chart.data = data2
            series.dataFields.value = "cost";
        }

        series.dataFields.category = "process_name";
        series.ticks.template.disabled = true;
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.maxWidth = 300;

        chart.legend.valign = "middle"
        chart.legend.scrollable = true;
        series.alignLabels = false;
        if (checkedA == true) {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        } else {
            series.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        }
        series.labels.template.radius = am4core.percent(-45);
        series.labels.template.fill = am4core.color("white");
        series.labels.template.relativeRotation = 90;
        series.hiddenState.properties.endAngle = -90;
        if (checkedA == true) {
            series.slices.template.tooltipText = "{category}: {value.value}" + " kWh";
        } else {
            series.slices.template.tooltipText = "{category}: {value.value} " + this.currency;
        }
        series.labels.template.adapter.add("radius", function (radius, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0;
            }
            return radius;
        });
        series.labels.template.adapter.add("fill", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return am4core.color("#ffffff");
            }
            return color;
        });
        series.labels.template.adapter.add("opacity", function (color, target) {
            if (target.dataItem && (target.dataItem.values.value.percent < 5)) {
                return 0
            }
            return 1;
        });
        if (theme == 'darktheme') {
            series.colors.list = [
                am4core.color("#e91e63"),
                am4core.color("#00bcd4"),
                am4core.color("#f7e53b"),
                am4core.color("#ffc107"),
                am4core.color("#2196f3"),
                am4core.color("#93c360"),
                am4core.color("#ab26c2"),
                am4core.color("#009688"),
                am4core.color("#d73e71"),
                am4core.color("#58e2c2"),
            ];
        }
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function (target) {
                if (target.pixelWidth <= 400) {
                    chart.legend.maxHeight = 150;

                    chart.legend.disabled = true;
                    return true;
                }
                if (target.pixelWidth > 400) {
                    chart.legend.maxHeight = 300;
                    chart.legend.disabled = false;
                    return false;
                }
                return false;
            },
            state: function (target, stateId) {
                if (target instanceof am4charts.PieSeries) {
                    var state = target.states.create(stateId);
                    return state;
                }
                return null;
            }
        });
        this.chart1 = chart;
    }
    createBarchart1(dial_data, theme) {
        // console.log(dial_data)
        if (theme == 'darktheme' || theme == 'greytheme') {
            am4core.useTheme(am4themes_dark);
        } else {
            am4core.unuseTheme(am4themes_dark);
        }
        var chart = am4core.create("barchartdiv", am4charts.XYChart);
        chart.logo.disabled = true;
        chart.cursor = new am4charts.XYCursor();
        chart.scrollbarX = new am4core.Scrollbar();

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.menu.align = "left";
        chart.exporting.menu.verticalAlign = "top";
        // will use this to store colors of the same items
        var colors = {};
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "new_category";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.grid.template.location = 0;

        // Configure axis label to wrap if the lenght exceeds
        var label = categoryAxis.renderer.labels.template;
        label.wrap = true;
        label.maxWidth = 150;

        categoryAxis.dataItems.template.text = "{new_category}";
        categoryAxis.adapter.add("tooltipText", function (tooltipText, target) {
            return categoryAxis.tooltipDataItem.dataContext.new_category;
        })
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "kWh";
        valueAxis.tooltip.disabled = true;
        valueAxis.min = 0;
        // single column series for all data
        var columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.columns.template.width = am4core.percent(80);
        columnSeries.tooltipText = "{new_category}: {valueY} " + "kWh";
        columnSeries.dataFields.categoryX = "new_category";
        columnSeries.dataFields.valueY = 'subprocess_kwh';
        // second value axis for quantity
        var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.title.text = " Cost - " + this.currency;
        valueAxis2.renderer.opposite = true;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.tooltip.disabled = true;
        // quantity line series
        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.tooltipText = "{new_category}: {valueY} " + this.currency;
        lineSeries.dataFields.categoryX = "new_category";
        lineSeries.dataFields.valueY = "subprocess_cost";
        lineSeries.yAxis = valueAxis2;
        var bullets = lineSeries.bullets.push(new am4charts.CircleBullet());
        lineSeries.stroke = am4core.color("black");
        lineSeries.fill = lineSeries.stroke;
        lineSeries.strokeWidth = 2;
        bullets.circle.radius = 2;
        lineSeries.snapTooltip = true;
        lineSeries.events.on("datavalidated", function () {
            lineSeries.dataItems.each(function (dataItem) {
                if (dataItem.dataContext.count / 2 == Math.round(dataItem.dataContext.count / 2)) {
                    dataItem.setLocation("categoryX", 0);
                }
                else {
                    dataItem.setLocation("categoryX", 0.5);
                }
            })
        })
        columnSeries.columns.template.adapter.add("fill", function (fill, target) {
            var name = target.dataItem.dataContext.description;
            if (!colors[name]) {
                colors[name] = chart.colors.next();
            }
            target.stroke = colors[name];
            return colors[name];
        })
        var rangeTemplate = categoryAxis.axisRanges.template;
        rangeTemplate.tick.disabled = false;
        rangeTemplate.tick.location = 0;
        rangeTemplate.tick.strokeOpacity = 0.6;
        rangeTemplate.tick.length = 60;
        rangeTemplate.grid.strokeOpacity = 0.5;
        rangeTemplate.label.tooltip = new am4core.Tooltip();
        rangeTemplate.label.tooltip.dy = -10;
        rangeTemplate.label.cloneTooltip = false;
        chart.data = dial_data;
        this.chart = chart;
    }



    render() {
        //const e1=new Date();
        const today = new Date();
        const e1 = new Date(today);
        //const e1=new Date(today.getDate() - 1);
        e1.setDate(today.getDate() - 1);
        //today.getDate() - 1
        const sy1 = e1.getFullYear();
        const sm1 = ("0" + (e1.getMonth() + 1)).slice(-2);
        const sd1 = ("0" + e1.getDate()).slice(-2);
        const currentDate = sy1.toString() + '-' + sm1.toString() + '-' + sd1.toString();
        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} showProcessDropdown={true} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Grouping Data
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            Grouping Configuration
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="2">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <GroupConfiguration ></GroupConfiguration>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="1">
                                                    <div>
                                                        {this.state.showcontent == true ?
                                                            <>
                                                                <FieldGroup
                                                                    control={this.PieChartForm}
                                                                    strict={false}
                                                                    render={({ get, invalid, value }) => (
                                                                        <form>
                                                                            <Row>
                                                                                <Col className="pr-1" md="2">
                                                                                    <FieldControl
                                                                                        name="start_date"
                                                                                        strict={false}
                                                                                        render={({ handler, touched, hasError }) => (
                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                    <Grid item>
                                                                                                        <TextField type="date" required fullWidth variant="outlined" label="Start Date"
                                                                                                            inputProps={{
                                                                                                                max: currentDate, // Set the maximum date to the current date
                                                                                                            }}
                                                                                                            error={touched && hasError("required")}  {...handler("text")} />
                                                                                                    </Grid>
                                                                                                    <span className="error">
                                                                                                        {touched
                                                                                                            && hasError("required")
                                                                                                            && `* Start Date is required`}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col className="pr-1" md="2">
                                                                                    <FieldControl
                                                                                        name="end_date"
                                                                                        strict={false}
                                                                                        render={({ handler, touched, hasError }) => (
                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                    <Grid item>
                                                                                                        <TextField type="date" required fullWidth variant="outlined" label="End Date"
                                                                                                            inputProps={{
                                                                                                                max: currentDate, // Set the maximum date to the current date
                                                                                                            }}
                                                                                                            error={touched && hasError("required")}  {...handler("text")} />
                                                                                                    </Grid>
                                                                                                    <span className="error">
                                                                                                        {touched
                                                                                                            && hasError("required")
                                                                                                            && `* End Date is required`}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                {/* <Col className="pr-1" md="4">
                                                                                    <FieldControl
                                                                                        name="shift"
                                                                                        strict={false}
                                                                                        render={({ handler, touched, hasError }) => (
                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                    <Grid item>
                                                                                                        <FormControl variant="outlined"
                                                                                                            margin={"1"}
                                                                                                            style={{ width: "100%", marginBottom: 32 }}>
                                                                                                            <InputLabel id="demo-mutiple-name-label300">Shift</InputLabel>
                                                                                                            <Select
                                                                                                                labelId="demo-mutiple-name-label300"
                                                                                                                id="demo-mutiple-name-label300"
                                                                                                                fullWidth variant="outlined"
                                                                                                                multiple required
                                                                                                                label="Shift"
                                                                                                                value={this.state.selectedshift}
                                                                                                                {...handler("text")}
                                                                                                            >
                                                                                                                {this.state.shiftlist.map(option => (
                                                                                                                    <MenuItem className={"formfields shiftoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.value} value={option.value} onClick={(e) => this.shifthandleChange(option)}>
                                                                                                                        {option.name}
                                                                                                                    </MenuItem>
                                                                                                                ))}
                                                                                                            </Select>
                                                                                                        </FormControl>
                                                                                                    </Grid>
                                                                                                    <span className="error">
                                                                                                        {touched
                                                                                                            && hasError("required")
                                                                                                            && `* Shift is required`}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Col> */}

                                                                            </Row>
                                                                            <Row>
                                                                                <Col className="pr-1" md="4">
                                                                                </Col>
                                                                                <Col className="pr-1" md="4">
                                                                                    <div className="login-button changepassword text-center">
                                                                                        <Button
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            disabled={invalid}
                                                                                            onClick={(event) => this.handlePieChartSubmit(event, this.PieChartForm.getRawValue())}
                                                                                        >
                                                                                            Generate
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="pr-1" md="4">
                                                                                </Col>
                                                                            </Row>
                                                                        </form>
                                                                    )}
                                                                />
                                                                <Row>
                                                                    {this.state.groupingData.map((item, index) => (
                                                                        <Col md="6" key={index}>
                                                                            <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                <CardHeader>
                                                                                    <CardTitle tag="h5" className="dashboardcardtitle">
                                                                                        <Switch
                                                                                            checked={item.checked}
                                                                                            color="primary"
                                                                                            name="checkedA"
                                                                                            onChange={(e) => this.handlePieToggleChange(e, index)}
                                                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                                            className={"switchtoggler " + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                                                        />
                                                                                        {item.checkeduom} - {item.name}
                                                                                    </CardTitle>
                                                                                </CardHeader>
                                                                                <CardBody>
                                                                                    <div id={`topchartdiv${index}`} style={{ width: "100%", height: '350px', fontFamily: 'Inter', fontWeight: '500' }}></div>
                                                                                    <hr />
                                                                                    <CardTitle tag="h5" className="dashboardcardtitle" style={{ textAlign: 'center' }}> Total : {item.checkedtotal} {(item.checkeduom == 'Cost' ? this.currency : "kWh")}</CardTitle>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                                <hr></hr>
                                                                <FieldGroup
                                                                    control={this.barChartForm}
                                                                    strict={false}
                                                                    render={({ get, invalid, value }) => (
                                                                        <form>
                                                                            <Row>
                                                                                <Col className="pr-1" md="2">
                                                                                    <FieldControl
                                                                                        name="start_date"
                                                                                        strict={false}
                                                                                        render={({ handler, touched, hasError }) => (
                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                    <Grid item>
                                                                                                        <TextField type="date" required fullWidth variant="outlined" label="Start Date"
                                                                                                            inputProps={{
                                                                                                                max: currentDate, // Set the maximum date to the current date
                                                                                                            }}
                                                                                                            error={touched && hasError("required")}  {...handler("text")} />
                                                                                                    </Grid>
                                                                                                    <span className="error">
                                                                                                        {touched
                                                                                                            && hasError("required")
                                                                                                            && `* Start Date is required`}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col className="pr-1" md="2">
                                                                                    <FieldControl
                                                                                        name="end_date"
                                                                                        strict={false}
                                                                                        render={({ handler, touched, hasError }) => (
                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                    <Grid item>
                                                                                                        <TextField type="date" required fullWidth variant="outlined" label="End Date"
                                                                                                            inputProps={{
                                                                                                                max: currentDate, // Set the maximum date to the current date
                                                                                                            }}
                                                                                                            error={touched && hasError("required")}  {...handler("text")} />
                                                                                                    </Grid>
                                                                                                    <span className="error">
                                                                                                        {touched
                                                                                                            && hasError("required")
                                                                                                            && `* End Date is required`}
                                                                                                    </span>
                                                                                                </Grid>
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">
                                                                                    <FieldControl
                                                                                        name="grouping_id"
                                                                                        strict={false}
                                                                                        render={({ handler, touched, hasError }) => (
                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                    <Grid item>
                                                                                                        <TextField fullWidth
                                                                                                            select
                                                                                                            label="Select Group *"
                                                                                                            variant="outlined"
                                                                                                            {...handler("text")}
                                                                                                            name="grouping_id"
                                                                                                            //   value={this.state.selectedCountryValue}
                                                                                                            onChange={this.handleEventInputChange}
                                                                                                            error={touched && hasError("required")}
                                                                                                        >
                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                Select Group
                                                                                                            </MenuItem>
                                                                                                            {this.state.groupList.map(option => (
                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                    {option.group_name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </TextField>
                                                                                                    </Grid>
                                                                                                    <span className="error">
                                                                                                        {touched
                                                                                                            && hasError("required")
                                                                                                            && `* Board is required`}
                                                                                                    </span>
                                                                                                </Grid>

                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                                <Col className="pr-1" md="3">
                                                                                    <FieldControl
                                                                                        name="grouping_process_id"
                                                                                        strict={false}
                                                                                        render={({ handler, touched, hasError }) => (
                                                                                            <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                                <Grid className="Formfieldcss" container spacing={1}>
                                                                                                    <Grid item>
                                                                                                        <TextField fullWidth
                                                                                                            select
                                                                                                            label="Select Process *"
                                                                                                            variant="outlined"
                                                                                                            {...handler("text")}
                                                                                                            //   value={this.state.selectedCountryValue}
                                                                                                            error={touched && hasError("required")}
                                                                                                        >
                                                                                                            <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} value='' >
                                                                                                                Select Process
                                                                                                            </MenuItem>
                                                                                                            {this.state.subgroupList.map(option => (
                                                                                                                <MenuItem className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} >
                                                                                                                    {option.process_name}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </TextField>
                                                                                                    </Grid>
                                                                                                    <span className="error">
                                                                                                        {touched
                                                                                                            && hasError("required")
                                                                                                            && `* Sub Process is required`}
                                                                                                    </span>
                                                                                                </Grid>

                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col className="pr-1" md="4">
                                                                                </Col>
                                                                                <Col className="pr-1" md="4">
                                                                                    <div className="login-button changepassword text-center">
                                                                                        <Button
                                                                                            type="submit"
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            disabled={invalid}
                                                                                            onClick={(event) => this.handleBarChartSubmit(event, this.barChartForm.getRawValue())}
                                                                                        >
                                                                                            Generate
                                                                                        </Button>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col className="pr-1" md="4">
                                                                                </Col>
                                                                            </Row>
                                                                        </form>
                                                                    )}
                                                                />
                                                                <Row>
                                                                    <div id="barchartdiv" style={{ width: "100%", height: "350px" }} className="sorafontclass"></div>
                                                                    {/* <Row>
                                                                        <Col className="pr-1 text-center" md="6">
                                                                            <span className="cardtitle-timetitle dialtotal"><i className={"fas fa-bolt newcolorblue " + (this.props.theme) + ' ' + (this.props.themecolor)} /> {this.state.total_consumption} kWh</span>
                                                                        </Col>
                                                                        <Col className="pr-1 text-center" md="6">
                                                                            <span className="cardtitle-timetitle dialtotal"><i className="fas fa-money-bill newcolorgreen" /> {this.state.prefix} {this.state.total_cost} {this.state.suffix}</span>
                                                                        </Col>
                                                                    </Row> */}
                                                                </Row>
                                                            </>
                                                            :
                                                            <Row>
                                                                <Col md="12">
                                                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                        <CardHeader>
                                                                        </CardHeader>
                                                                        <CardBody>
                                                                            <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                                                        </CardBody>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>

                        </Row>
                    }
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}
export default connect(mapStateToProps)(GroupingData);

