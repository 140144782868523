/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from "reactstrap";
import { routes, routesUS, routeselect } from "../../routes.js";
import * as apiService from '../../services/apiService';
import { connect } from 'react-redux';
import Loader from '../../services/loader';
import { routefunction } from "../../routes"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import AlertModal from './AlertModal.jsx';
import './AlertStyles.css';
import alertSound from '../../assets/audio/board_offline.mp3';
// import TopNavDropdown from '../../views/TopDropdown';

// Alert System Configuration  
const ALERT_CONFIG = {  
  POLLING_INTERVAL: 180000, // 3 minutes       // How often to check for alerts (30000 = 30 seconds)  
  MIN_FETCH_INTERVAL: 30000,      // Minimum time between API calls (30000 = 30 seconds)  
  AUTO_MODAL_DISPLAY_TIME: 0,  // How long to show the auto modal (5000 = 5 seconds)  
  RETRY_ON_ERROR_DELAY: 60000,    // Retry delay if API call fails (60000 = 60 seconds)  
  MAX_ALERTS_DISPLAY: 250,         // Maximum number of alerts to display  
  AUTO_REFRESH_ON_VISIBILITY: true, // Whether to refresh when tab becomes visible
  AUDIO_ALERT: {  
    ENABLED: true,                // Enable/disable audio alerts  
    FILE_PATH: alertSound // Path to the audio file  
  }  
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,  
      dropdownOpen: false,  
      color: "transparent",  
      loader: false,  
      username: '',  
      routing: [],  
      alerts: [],  
      alertCount: 0,  
      isAlertModalOpen: false,  
      lastCustomerId: null,  
      lastFetchTime: 0,
      isAutoModal: false,
      alertsDisabled: false,
      // selectedcustomer: '',  
      // selectedboard: '',
      // selectedcustomer: localStorage.getItem('selectedcustomer') || '',  
      // selectedboard: localStorage.getItem('selectedboard') || '',  
    };
    // Bind methods in constructor  
    this.toggle = this.toggle.bind(this);  
    this.dropdownToggle = this.dropdownToggle.bind(this);  
    this.sidebarToggle = React.createRef();  
    this.getAlerts = this.getAlerts.bind(this);  
    this.toggleAlertModal = this.toggleAlertModal.bind(this);  
    this.openSidebar = this.openSidebar.bind(this);  
    this.updateColor = this.updateColor.bind(this);  
    this.logout = this.logout.bind(this);  
    this.changepassword = this.changepassword.bind(this);
    // Create audio element  
    this.alertSound = new Audio(ALERT_CONFIG.AUDIO_ALERT.FILE_PATH);  
    this.alertSound.preload = 'auto';
    this.handleAlertPreferencesChange = this.handleAlertPreferencesChange.bind(this);
  }

  handleAlertPreferencesChange = (checked) => {  
    this.setState({ alertsDisabled: checked }, () => {  
      // Save to session storage  
      try {  
        sessionStorage.setItem(ALERT_CONFIG.STORAGE_KEY, JSON.stringify(checked));  
      } catch (error) {  
        console.error('Error saving alert preferences:', error);  
      }  
    });  
  };

  // Add method to play audio alert  
  playAlertSound = () => {  
    if (ALERT_CONFIG.AUDIO_ALERT.ENABLED) {  
      this.alertSound.currentTime = 0; // Reset audio to start  
      this.alertSound.play().catch(error => {  
        console.warn('Audio playback failed:', error);  
      });  
    }  
  };

  handleCustomerChange = () => {    
    if (this._isMounted) {    
      this.setState({   
        lastFetchTime: 0,  
        alerts: [],  
        alertCount: 0  
      }, () => {    
        // Only fetch alerts, don't show modal  
        this.getAlerts(false);    
      });    
    }  
  }

  // Add getAlerts as a class method  
  getAlerts = (showAutoModal = true) => {    
    const customerId = localStorage.getItem('selectedcustomer');    
    const now = Date.now();    
      
    if (!customerId || !this._isMounted) {    
      return;    
    }    
      
    // Use the configured minimum fetch interval  
    if (now - this.state.lastFetchTime < ALERT_CONFIG.MIN_FETCH_INTERVAL) {    
      return;    
    }    
      
    apiService.getAlerts(customerId)    
      .then(response => {    
        if (this._isMounted) {    
          const alerts = response.data.alerts || [];
          // Limit the number of alerts if needed  
          const limitedAlerts = alerts.slice(0, ALERT_CONFIG.MAX_ALERTS_DISPLAY);    
          const unreadAlerts = limitedAlerts.filter(alert => alert && alert.status === 0);    
          const alertCount = unreadAlerts.length;    
            
          this.setState({    
            alerts: limitedAlerts,    
            alertCount,    
            lastFetchTime: now    
          }, () => {    
            // Only show auto modal if showAutoModal is true and there are offline boards and play sound 
            if (showAutoModal && alertCount > 0 && !this.state.isAlertModalOpen && !this.state.alertsDisabled) {    
              this.setState({    
                isAlertModalOpen: true,    
                isAutoModal: true    
              }, () => {  
                // Play audio alert only if alerts are not disabled  
              if (!this.state.alertsDisabled) {  
                this.playAlertSound();
              }  
              });    
            // Auto close the modal after configured time if it's auto-opened  
            if (ALERT_CONFIG.AUTO_MODAL_DISPLAY_TIME > 0) {  
              setTimeout(() => {  
                if (this._isMounted && this.state.isAutoModal) {  
                  this.setState({  
                    isAlertModalOpen: false,  
                    isAutoModal: false  
                  });  
                }  
              }, ALERT_CONFIG.AUTO_MODAL_DISPLAY_TIME);  
            }  
          }    
        });    
      }    
    })    
    .catch(error => {    
      console.error('Error fetching alerts:', error);    
      if (this._isMounted) {    
        this.setState({    
          alerts: [],    
          alertCount: 0    
        });  
        // Retry after configured delay on error  
        setTimeout(() => {  
          if (this._isMounted) {  
            this.getAlerts(false);  
          }  
        }, ALERT_CONFIG.RETRY_ON_ERROR_DELAY);  
      }    
    });    
};
  
  componentWillUnmount() {  
    this._isMounted = false;  
    if (this.alertPollingInterval) {  
      clearInterval(this.alertPollingInterval);  
    }  
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);  
  // Clean up audio  
  if (this.alertSound) {  
    this.alertSound.pause();  
    this.alertSound.src = '';  
  }  
}
  
toggleAlertModal = () => {  
  this.setState(prevState => ({  
    isAlertModalOpen: !prevState.isAlertModalOpen,
    isAutoModal: false  
  }));  
};  

toggle = () => {  
  if (this.state.isOpen) {  
    this.setState({  
      color: "transparent"  
    });  
  } else {  
    this.setState({  
      color: "dark"  
    });  
  }  
  this.setState({  
    isOpen: !this.state.isOpen  
  });  
};  

dropdownToggle = (e) => {  
  this.setState({  
    dropdownOpen: !this.state.dropdownOpen  
  });  
};  

getBrand = () => {  
  let brandName = "Default Brand";  
  this.state.routing.map((prop, key) => {  
    if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {  
      brandName = prop.name;  
    }  
    return null;  
  });  
  return brandName;  
};  

openSidebar = () => {  
  document.documentElement.classList.toggle("nav-open");  
  this.sidebarToggle.current.classList.toggle("toggled");  
};  

updateColor = () => {  
  if (window.innerWidth < 993 && this.state.isOpen) {  
    this.setState({  
      color: "dark"  
    });  
  } else {  
    this.setState({  
      color: "transparent"  
    });  
  }  
};  

logout = (e) => {  
  e.preventDefault();  
  this.setState({ loader: true });  
  apiService.Logout()  
    .then(response => {  
      this.setState({ loader: false });  
      apiService.Alerts("Logged Out Successfully !!!!");  
      localStorage.clear();  
      this.props.changeTheme('lighttheme', 'blackcolor', 'none');  
      this.props.history.push('/neurobotz/login');  
    })  
    .catch(error => {  
      this.setState({ loader: false });  
      localStorage.clear();  
      this.props.history.push('/neurobotz/login');  
      this.props.changeTheme('lighttheme', 'blackcolor', 'none');  
      apiService.ErrorMessage(error);  
    });  
};  

changepassword = (e) => {  
  e.preventDefault();  
  this.props.history.push('/neurobotz/changepassword');  
};

componentDidMount() {  
  this._isMounted = true;  
  var x = routeselect();  
    
  this.setState({  
    routing: x,  
    username: localStorage.getItem('username'),  
    lastCustomerId: localStorage.getItem('selectedcustomer')  
  }, () => {  
    this.getAlerts(false); // Don't show modal on initial load  
  });  

  window.addEventListener("resize", this.updateColor.bind(this));  
  document.documentElement.classList.toggle("nav-open");  
  this.sidebarToggle.current.classList.toggle("toggled");  

  // Use the configured polling interval  
  this.alertPollingInterval = setInterval(() => {    
    if (this._isMounted && document.visibilityState === 'visible') {    
      this.getAlerts(true);  
    }    
  }, ALERT_CONFIG.POLLING_INTERVAL);    

  // Only add visibility listener if configured  
  if (ALERT_CONFIG.AUTO_REFRESH_ON_VISIBILITY) {  
    document.addEventListener('visibilitychange', this.handleVisibilityChange);    
  }
  
  // Load alert preferences from session storage  
  try {  
    const preferences = sessionStorage.getItem(ALERT_CONFIG.STORAGE_KEY);  
    if (preferences !== null) {  
      this.setState({ alertsDisabled: JSON.parse(preferences) });  
    }  
  } catch (error) {  
    console.error('Error loading alert preferences:', error);  
  }  
}

handleVisibilityChange = () => {    
  if (ALERT_CONFIG.AUTO_REFRESH_ON_VISIBILITY &&   
      document.visibilityState === 'visible' &&   
      this._isMounted) {    
    const now = Date.now();    
    if (now - this.state.lastFetchTime >= ALERT_CONFIG.MIN_FETCH_INTERVAL) {    
      this.getAlerts(false);  
    }    
  }    
};  

componentDidUpdate(prevProps, prevState) {  
  const currentCustomerId = localStorage.getItem('selectedcustomer');  
  if (currentCustomerId !== this.state.lastCustomerId) {  
    this.setState({   
      lastCustomerId: currentCustomerId,  
      lastFetchTime: 0, // Reset fetch time to force immediate fetch
      isAlertModalOpen: false, // Close modal if it's open  
      isAutoModal: false,  
    }, () => {  
      this.getAlerts(false); // Don't show modal on customer change 
    });  
  }  
}

  routefunction() {
    var x = routeselect()
    this.setState({ routing: x })
  }

  render() {
    return (
      <>

        <Navbar
          color={
            this.props.location.pathname.indexOf("full-screen-maps") !== -1
              ? "dark"
              : this.state.color
          }
          expand="lg"
          className={
            this.props.location.pathname.indexOf("full-screen-maps") !== -1
              ? "navbar-absolute fixed-top"
              : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "") +
              (this.props.bgColor === "black" ? "lighttheme" : this.props.bgColor === "blue" ? "bluelighttheme" : this.props.bgColor === "grey" ? "greytheme" : "darktheme")
          }
        >
          {
            this.state.loader && <Loader />
          }
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-toggle">
                <button
                  type="button"
                  ref={this.sidebarToggle}
                  className="navbar-toggler"
                  onClick={() => this.openSidebar()}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="javascript:void(0);">{this.getBrand()}</NavbarBrand>&nbsp;
            </div>
            <NavbarToggler onClick={this.toggle}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>
            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="justify-content-end"
            >
              <Nav navbar>
                <div className="d-flex align-items-center mr-3">
                  <div className="header-alerts">
                    <div className="alert-icon" onClick={this.toggleAlertModal}>
                      <FontAwesomeIcon icon={faBell} style={{ marginTop: '2px' }} />
                      {this.state.alertCount > 0 && (  
                       <span className="alert-badge">{this.state.alertCount}</span>
                      )}
                    </div>
                  </div>
                </div>
                <Dropdown
                  nav
                  isOpen={this.state.dropdownOpen}
                  toggle={e => this.dropdownToggle(e)}
                >
                  <DropdownToggle caret nav>
                    <i className="nc-icon nc-single-02" />&nbsp; <span className="usernamefont">{this.state.username}</span>
                    <p>
                      <span className="d-lg-none d-md-block"></span>
                    </p>
                  </DropdownToggle>
                  <DropdownMenu right className={(this.props.bgColor === "black" ? "lighttheme" : this.props.bgColor === "blue" ? "lighttheme" : this.props.bgColor === "grey" ? "greytheme" : "darktheme")}>
                    <DropdownItem className={"atextdecoration " + (this.props.bgColor === "black" ? "lighttheme blackcolor" : this.props.bgColor === "blue" ? "lighttheme bluecolor" : this.props.bgColor === "grey" ? "greytheme greycolor" : "darktheme")} tag="a" onClick={(e) => this.changepassword(e)}>Change Password</DropdownItem>
                    <DropdownItem className={"atextdecoration " + (this.props.bgColor === "black" ? "lighttheme blackcolor" : this.props.bgColor === "blue" ? "lighttheme bluecolor" : this.props.bgColor === "grey" ? "greytheme greycolor" : "darktheme")} tag="a" onClick={(e) => this.logout(e)}>Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <AlertModal
                  isOpen={this.state.isAlertModalOpen}  
                  toggle={this.toggleAlertModal}  
                  alerts={this.state.alerts}  
                  loading={this.state.loader}  
                  isAutoModal={this.state.isAutoModal}
                  alertsDisabled={this.state.alertsDisabled}  
                  onAlertsDisabledChange={this.handleAlertPreferencesChange}
                  className="alert-modal"
                />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        {/* <TopNavDropdown  
        customerhandler={this.customerhandler}  
        selectedcustomer={this.selectedcustomer}  
        boardhandler={this.boardhandler}  
        selectedboard={this.selectedboard}  
        showProcessDropdown={true}  
      /> */}
      </>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    timestamp: state.timestamp
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeTheme: (theme, themecolor, charttheme) => { dispatch({ type: 'CHANGE_THEME', theme: theme, themecolor: themecolor, charttheme: charttheme }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
