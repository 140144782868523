import React from "react";

import axios from 'axios';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import { v3_server_check } from "../routes"

var host = window.location.hostname
const new_backend = "128.199.27.22"
const local = "localhost"

// var URL = "https://139.59.66.25:7005/"
var URL = "https://backend.mysmartpo.com/"
// var URL="http://127.0.0.1:8081/"
var TIME_URL = "http://161.35.15.52:7005/"
var TREE_URL = "http://68.183.83.110:9700/"

if (host == new_backend) {
  URL = "http://128.199.27.22:7000/"
  TIME_URL = " http://128.199.27.22:7005/"
}


//production backend
// const URL="http://139.59.66.25:7005/"
// const TIME_URL="http://161.35.15.52:7005/"

//new instance
// const URL="http://128.199.27.22:7000/"
// const TIME_URL=" http://128.199.27.22:7005/"

export const Login = value => {
  let creds = 'email=' + value.email + '&' + 'password=' + value.password;
  var options = {
    url: URL + 'api/customer/login/',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }, data: creds
  };
  return axios(options)
};

export const Logout = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customer/logout/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const Forgetpassword = value => {
  let mail = 'email=' + value.email + '&' + 'host=' + value.loc;
  var options = {
    url: URL + 'api/customer/forgot_password/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, data: mail
  };
  return axios(options)
};

export const SetSlider = value => {
  let mail = 'led=' + value.led + '&' + 'value=' + value.value;
  var options = {
    url: URL + 'api/light/led/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, data: mail
  };
  return axios(options)
};

export const Changepassword = value => {
  let token = localStorage.getItem('token');
  let user_id = localStorage.getItem('user_id');
  let creds = 'old_password=' + value.current_password + '&' + 'new_password=' + value.new_password + '&' + 'new_password_again=' + value.new_password_again + '&' + 'customer_id=' + user_id;
  var options = {
    url: URL + 'api/customer/change_password/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const Resetpassword = (value, queries) => {
  let creds = 'customer_id=' + queries.uid + '&' + 'token=' + queries.token + '&' + 'new_password=' + value.new_password + '&' + 'new_password_again=' + value.new_password_again;
  var options = {
    url: URL + 'api/customer/reset_password/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, data: creds
  };
  return axios(options)
};


//  Customers

export const getCustomers = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + '/api/customers/list/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const getDropdown = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dropdown/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const getStates = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/state/list/?country=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const getCurrency = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/country/currency/?country=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const getCustomerBoards = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customers/board/dropdown/list/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const addCustomer = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customers/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const updateCustomer = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customers/update/' + value.id + '/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const deleteCustomer = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customers/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};


export const addBoardinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boardinfo/?board_id=' + value.board_id,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const updateBoardinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boardinfo/' + value.id + '/?board_id=' + value.board_id,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const getBoardinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boardinfo/?board_id=' + value,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const deleteBoardinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boardinfo/' + value.id + '/?board_id=' + value.board_id,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const addProductinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productinfo/?customer_id=' + value.customer_id,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const updateProductinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productinfo/' + value.id + '/?customer_id=' + value.customer_id,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const getProductinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productinfo/?customer_id=' + value,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const deleteProductinfo = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productinfo/' + value.id + '/?customer_id=' + value.customer_id,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

// Boards

export const getBoards = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/board/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const AddBoards = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/board/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const UpdateBoards = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/board/' + value.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const DeleteBoards = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/board/' + value + '/',
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

// Top dropdown

export const topCustomerDropdown = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customers/dropdown/list/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const topBoardDropdown = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customers/dropdown/list/' + value + '/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

// Dashboard

export const Dashboarddial = (value, fromdate, todate) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dashboarddial/?board_id=' + value + '&from_date=' + fromdate + '&to_date=' + todate,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const NewDashboarddialv2 = (value, fromdate, todate, uom) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dashboarddial/?board_id=' + value + '&from_date=' + fromdate + '&to_date=' + todate + '&types=' + uom,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const NewDashboarddial = (value, fromdate, todate) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/dashboarddial/?board_id=' + value + '&from_date=' + fromdate + '&to_date=' + todate

    ,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const Dashboardrealtime = (startdate, enddate, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dash_realtime/?board_id=' + board_id + '&from_date=' + startdate + '&to_date=' + enddate,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const NewDashboardrealtimev2 = (startdate, enddate, board_id, uom) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dash_realtime/?board_id=' + board_id + '&from_date=' + startdate + '&to_date=' + enddate + '&types=' + uom,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const NewDashboardrealtime = (startdate, enddate, uom, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/dash_realtime/?board_id=' + board_id + '&from_date=' + startdate + '&to_date=' + enddate,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const Dashboardcardboxes = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dashboard/?board_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const NewDashboardcardboxesv2 = (value, uom) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dashboard/?board_id=' + value + '&types=' + uom,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const NewDashboardcardboxes = (startdate, enddate, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/dashboard/?board_id=' + board_id + '&from_date=' + startdate + '&to_date=' + enddate,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}
export const dashboard_flow = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/flowrate/dashboard/page/?board_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}
// Landing Page
export const Landingpageboxes = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/landing/page/?board_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

// Realtime

export const dial_data_flow = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/flowrate/realtime/?board_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}
export const monthly_data_flow = (startdate, enddate) => {
  let token = localStorage.getItem('token');
  let board_id = localStorage.getItem('selectedboard');

  var options = {
    url: URL + 'api/flowrate/realtimemonthlydata/?from_date=' + startdate + '&to_date=' + enddate + '&board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}
export const realtime_data_flow = (startdate, enddate) => {
  let token = localStorage.getItem('token');
  let board_id = localStorage.getItem('selectedboard');

  var options = {
    url: URL + 'api/flowrate/realtimedata/?from_date=' + startdate + '&to_date=' + enddate + '&board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}
export const Realtimechartdata = (startdate, enddate, uom, board_id, minutes) => {
  let token = localStorage.getItem('token');
  var url = URL + 'api/realtime/?from_date=' + startdate + '&to_date=' + enddate + '&Type=' + uom + '&board_id=' + board_id
  if (v3_server_check() == true) {
    url = TIME_URL + 'api/v3/realtime/?from_date=' + startdate + '&to_date=' + enddate + '&Type=' + uom + '&board_id=' + board_id + '&minutes=' + minutes
  }
  var options = {
    url: url,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const TimeRealtimechartdata = (startdate, enddate, uom, board_id, minutes) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/realtime/?from_date=' + startdate + '&to_date=' + enddate + '&Type=' + uom + '&board_id=' + board_id + '&minutes=' + minutes,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

// History
export const Historychartdata = (period, range, unit, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/history/?duration=' + period + '&value=' + range + '&units=' + unit + '&board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const newHistorychartdata = (period, range, unit, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/history/?duration=' + period + '&units=' + unit + '&board_id=' + board_id + '&value=' + range,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

// Savings 

export const OldSavingschartdata = (period, range, unit, board_id) => {
  let token = localStorage.getItem('token');
  var url = URL + 'api/savings/?duration=' + period + '&value=' + range + '&units=' + unit + '&board_id=' + board_id
  if (v3_server_check() == true) {
    url = TIME_URL + 'api/v3/savings?duration=' + period + '&value=' + range + '&units=' + unit + '&board_id=' + board_id
  }
  var options = {
    url: url,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const Savingschartdata = (period, range, unit, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/savings?duration=' + period + '&value=' + range + '&units=' + unit + '&board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

// Interventions
export const Interventionlist = (board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/intervention/?board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const AddInterventions = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/intervention/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const UpdateInterventions = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/intervention/' + value.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const DeleteInterventions = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/intervention/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

// Savings v Baseline
export const Summarychartdata = (period, range, unit, board_id) => {
  let token = localStorage.getItem('token');

  var options = {
    url: URL + 'api/summary_baseline/?duration=' + period + '&value=' + range + '&units=' + unit + '&board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const newSummarychartdata = (period, range, unit, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/summary_baseline?duration=' + period + '&value=' + range + '&units=' + unit + '&board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const AddBaseline = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/baseline/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const GetBaseline = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/baseline/?board_id=' + value,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const UpdateBaseline = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/baseline/' + value.id + '/?board_id=' + value.board_id,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const DeleteBaseline = (value, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/baseline/' + value + '/?board_id=' + board_id,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

// Tariff
export const GetHistoryTable = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/tariffstructure/?board_id=' + value,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const GetTariffTable = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/tariff/?board_id=' + value,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const AddTariffStructure = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/tariffstructure/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const AddTariff = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/tariff/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const UpdateTariff = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/tariff/' + value.id + '/?board_id=' + value.board_id,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const DeleteTariff = (value, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/tariff/' + value + '/?board_id=' + board_id,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

// Power Analyser
export const PAchartdata = (startdate, enddate, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/power_analyser/?from_date=' + startdate + '&to_date=' + enddate + '&board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const TimePAchartdata = (startdate, enddate, uom, board_id, minutes) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/power_analyser?from_date=' + startdate + '&to_date=' + enddate + '&board_id=' + board_id + '&unit=' + uom + '&minutes=' + minutes,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

// Production
export const Boarddropdowndata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/customers/dropdown/list/' + value + '/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const getdailyProductionchartdata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/product/dailydata/analysis/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const getmonthlyProductionchartdata = (period, range, board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/product/monthlydata/ukganalysis/?duration=' + period + '&value=' + range + '&board_id=' + board_id,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getdailyUKGchartdata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/product/dailydata/ukganalysis/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const Belowtabledailydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productdailydata/?customer_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const postBelowtabledailydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productdailydata/?customer_id=' + value.customer_id,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const putBelowtabledailydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productdailydata/' + value.id + '/?customer_id=' + value.customer_id,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const deletetabledailydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productdailydata/' + value.id + '/?customer_id=' + value.customer_id,
    method: 'DELETE',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const dailyproductvariationdata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productinfo/?customer_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const postBelowtablemonthlydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productmonthlydata/?customer_id=' + value.customer_id,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const putBelowtablemonthlydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productmonthlydata/' + value.id + '/?customer_id=' + value.customer_id,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const deletetablemonthlydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productmonthlydata/' + value.id + '/?customer_id=' + value.customer_id,
    method: 'DELETE',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const Belowtablemonthlydata = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/productmonthlydata/?customer_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

// Logs
export const LogsTable = (value) => {
  let token = localStorage.getItem('token');
  var url = URL + 'api/boarddata/getlist/?board_id=' + value
  if (v3_server_check() == true) {
    url = TIME_URL + 'api/v3/boarddata/get_list?board_id=' + value + '&limit=60'
  }
  var options = {
    url: url,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}
export const LogsTable_flow = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/flowrate/logs/?board_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const newLogsTable = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TIME_URL + 'api/v3/boarddata/get_list?board_id=' + value + '&limit=60',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}
export const LogsTable_board = (value, page, board) => {
  let token = localStorage.getItem('token');
  var url = URL + 'api/boarddata/getlists/?board_id=' + board + '&start_date=' + value.start_date + '&end_date=' + value.start_date + "&page=" + page

  var options = {
    url: url,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}


export const DownloadLogsTable = (value) => {
  let creds = 'start_date=' + value.start_date + '&end_date=' + value.end_date + '&board_id=' + value.board_id + '&start_time=' + value.startTime + '&end_time=' + value.endTime;
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boarddata/getlist/download/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
}
export const DownloadLogsTable_flow = (value) => {
  let creds = 'start_date=' + value.start_date + '&end_date=' + value.end_date + '&board_id=' + value.board_id;
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/flowrate/download/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
}

export const Alerts = data => {
  Alerts_Function(data, "#00B900")
};
export const Alerts_info = data => {
  Alerts_Function(data, "#4285f4")
};
export const Alerts_error = data => {
  Alerts_Function(data, "#ff3547")
};
const Alerts_Function = (data, color) => {
  setTimeout(() => {
    var all = document.getElementsByClassName('Toaster__alert');
    console.log(all)
    for (var i = 0; i < all.length; i++) {
      all[i].style.background = color;

    }
  }, 1);
  return toaster.notify(<label style={{ color: "white", fontWeight: 600, fontSize: 14 }}>{data}</label>, {
    duration: 2000,
    position: 'top-right',
  })
};

export const ErrorMessage = (error) => {
  var x = error.response
  if (x === undefined) {
  } else {
    if (x.status === 500) {
      Alerts_error("500 Internel Server Error")
    } else if (x.status === 403) {
      Alerts_error("Token Expired. Please Logout")
    } else {
      if (x.data[0] != undefined) {
        Alerts_error(x.data[0].message)
      } else {
        var y = Object.keys(x.data)[0]
        if (y != undefined) {
          var z = x.data[y][0]
          if (z != undefined) {
            Alerts_error(z)
          }
        }

      }
    }
  }
};



export const getThermostatDropdown = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dropdown/list/',
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const getThermostat = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/thermo/lists/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const addThermostat = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');

  let creds = 'board_id=' + data.board + '&' + 'set_temp=' + data.temperature + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc + '&' + 'created_by=' + createdby;

  var options = {
    url: URL + 'api/thermo/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editThermostat = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  let creds = 'board_id=' + data.board + '&' + 'set_temp=' + data.temperature + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc + '&' + 'created_by=' + createdby + '&' + 'updated_by=' + createdby;

  var options = {
    url: URL + 'api/thermo/update/' + data.id + '/?' + p,
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deleteThermostat = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/thermo/delete/' + value + "/?" + p,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getScheduleHvac = value => {
  let token = localStorage.getItem('token');
  let creds = 'thermo_id=' + value

  var options = {
    url: URL + 'api/hvac/schedule/lists/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const hvacRemoteApi = (thermo_id, thermo_name, power, fan, swing, temp) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  let creds = 'board_id=' + y + '&' + 'thermo_id=' + thermo_id + '&' + 'thermo_name=' + thermo_name + '&' + 'fan_speed=' + fan + '&' + 'operation=' + power + '&' + 'temperature=' + temp + '&' + 'swing_status=' + swing;
  var options = {
    url: URL + 'api/hvac/schedule/Remote/Create/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const hvacRemoteApiSingle = (thermo_id, thermo_name, name, value) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  let creds = 'board_id=' + y + '&' + 'thermo_id=' + thermo_id + '&' + 'thermo_name=' + thermo_name + '&' + name + '=' + value;
  var options = {
    url: URL + 'api/hvac/schedule/Remote/Create/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

// export const getScheduleCurrentHvac = value => {
//   let token = localStorage.getItem('token');
//   let creds = 'thermo_id=' + value

//   var options = {
//   url: URL+'api/hvac/schedule/current_hvac/?'+ creds,
//   method: 'GET',
//     headers: {
//       'Authorization': 'token ' + token
//     }
//   };
//   return axios(options)
// };

export const addSheduleHvac = (data, startDate, endDate, thermo) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.endTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id;

  var options = {
    url: URL + 'api/hvac/schedule/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editScheduleHvac = (data, startDate, endDate, thermo) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var t = data.startTime.split(":")
  var u = t[0] + ":" + t[1]
  var v = data.endTime.split(":")
  var p = v[0] + ":" + v[1]
  let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + u + '&' + 'to_time=' + p + '&' + 'updated_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id;

  var options = {
    url: URL + 'api/hvac/schedule/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deleteScheduleHvac = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/hvac/schedule/delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getScheduleHistoryHvac = value => {
  let token = localStorage.getItem('token');
  let creds = 'thermo_id=' + value

  var options = {
    url: URL + 'api/hvac/schhis/list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const editStatusHvac = (id, status) => {
  let token = localStorage.getItem('token');
  let creds = 'status=' + status;

  var options = {
    url: URL + 'api/hvac/status_update/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const getDemand = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/demand/get_demand_value/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const addAlarm = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var p = "&board_id=" + y

  let creds = 'type=' + data.type + '&' + 'load=' + data.load + '&' + 'description=' + data.desc + '&' + 'delay_time=' + data.time + '&' + 'value=' + data.value + '&' + 'created_by=' + createdby + p;

  var options = {
    url: URL + 'api/demand/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editAlarm = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  var p = "&board_id=" + y

  let creds = 'type=' + data.type + '&' + 'load=' + data.load + '&' + 'description=' + data.desc + '&' + 'delay_time=' + data.time + '&' + 'value=' + data.value + '&' + p;

  var options = {
    url: URL + 'api/demand/update/' + data.id + '/?' + p, creds,
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deleteAlarm = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/demand/delete/' + value + "/?" + p,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getLoad = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/loadschedule/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const addLoad = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'sequence=' + data.load_seq + '&' + 'load_id=' + data.load_id + '&' + 'time=' + data.time + '&' + 'date=' + data.fdate + '&' + 'load_state=' + data.load_status + '&' + 'description=' + data.description + '&' + 'customer_id=' + customer_id + '&' + 'created_by=' + createdby + '&' + 'updated_by=' + createdby + p;

  var options = {
    url: URL + 'api/loadschedule/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editLoad = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'sequence=' + data.load_seq + '&' + 'load_id=' + data.load_id + '&' + 'time=' + data.time + '&' + 'date=' + data.fdate + '&' + 'load_state=' + data.load_status + '&' + 'description=' + data.description + '&' + 'customer_id=' + customer_id + '&' + 'created_by=' + createdby + '&' + 'updated_by=' + createdby + p;

  var options = {
    url: URL + 'api/loadschedule/' + data.id + '/?' + p, creds,
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deleteLoad = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/loadschedule/' + value + "/?" + p,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getAlert = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/alarm/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addAlert = (data) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var creds = {
    board_id: y,
    data: data

  }

  var options = {
    url: URL + 'api/alarm/',
    method: 'POST',
    headers: { 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};
export const getAlertLogs = (date1, date2) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "&board_id=" + y

  var options = {
    url: URL + 'api/alarm/logs/?from_date=' + date1 + "&to_date=" + date2 + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

// -----------------tap setting module------------
export const getVoltage = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/voltage_tapping_setting/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const addVoltage = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'ibvl_voltage_less_than=' + data.ibvl_voltage_less_than + '&' + 'tt1vl_from_voltage=' + data.tt1vl_from_voltage + '&' + 'tt1vl_to_voltage=' + data.tt1vl_to_voltage + '&' + 'tt1vl_delay=' + data.tt1vl_delay + '&' + 'tt2vl_from_voltage=' + data.tt2vl_from_voltage + '&' + 'tt2vl_to_voltage=' + data.tt2vl_to_voltage + '&' + 'tt2vl_delay=' + data.tt2vl_delay + '&' + 'customer_id=' + customer_id + '&' + p + '&' + 'created_by=' + createdby + '&' + 'updated_by=' + createdby;

  var options = {
    url: URL + 'api/voltage_tapping_setting/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editVoltage = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'ibvl_voltage_less_than=' + data.ibvl_voltage_less_than + '&' + 'tt1vl_from_voltage=' + data.tt1vl_from_voltage + '&' + 'tt1vl_to_voltage=' + data.tt1vl_to_voltage + '&' + 'tt1vl_delay=' + data.tt1vl_delay + '&' + 'tt2vl_from_voltage=' + data.tt2vl_from_voltage + '&' + 'tt2vl_to_voltage=' + data.tt2vl_to_voltage + '&' + 'tt2vl_delay=' + data.tt2vl_delay + '&' + 'customer_id=' + customer_id + '&' + p + '&' + 'updated_by=' + createdby;

  var options = {
    url: URL + 'api/voltage_tapping_setting/' + data.id + '/?' + p, creds,
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deleteVoltage = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/voltage_tapping_setting/' + value + "/?" + p,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getCurrent = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/current_tapping_setting/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const addCurrent = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'asl_current_greater_than=' + data.asl_current_greater_than + '&' + 'dbcl_from_current=' + data.dbcl_from_current + '&' + 'dbcl_to_current=' + data.dbcl_to_current + '&' + 'dbcl_delay=' + data.dbcl_delay + '&' + 'ibcl_current_greater_than=' + data.ibcl_current_greater_than + '&' + 'customer_id=' + customer_id + p + '&' + 'created_by=' + createdby + '&' + 'updated_by=' + createdby;

  var options = {
    url: URL + 'api/current_tapping_setting/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editCurrent = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'asl_current_greater_than=' + data.asl_current_greater_than + '&' + 'dbcl_from_current=' + data.dbcl_from_current + '&' + 'dbcl_to_current=' + data.dbcl_to_current + '&' + 'dbcl_delay=' + data.dbcl_delay + '&' + 'ibcl_current_greater_than=' + data.ibcl_current_greater_than + '&' + 'customer_id=' + customer_id + '&' + p + '&' + 'updated_by=' + createdby;

  var options = {
    url: URL + 'api/current_tapping_setting/' + data.id + '/?' + p, creds,
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deleteCurrent = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/current_tapping_setting/' + value + "/?" + p,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getPhase = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/voltage_tapping_system_selection/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const addPhase = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'selection=' + data.phase + '&' + 'customer_id=' + customer_id + p + '&' + 'created_by=' + createdby + '&' + 'updated_by=' + createdby;

  var options = {
    url: URL + 'api/voltage_tapping_system_selection/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editPhase = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'selection=' + data.phase + '&' + 'customer_id=' + customer_id + '&' + p + '&' + 'updated_by=' + createdby;

  var options = {
    url: URL + 'api/voltage_tapping_system_selection/' + data.id + '/?' + p, creds,
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deletePhase = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/voltage_tapping_system_selection/' + value + "/?" + p,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getReadings = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/readinglist/?' + p + '&date=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const editReadings = (data) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')
  var customer_id = localStorage.getItem('selectedcustomer')

  var p = "&board_id=" + y

  let creds = 'first_reading_id=' + data.first_reading_id + '&' +
    'first_reading=' + data.first_reading + '&' +
    'first_reading_update=' + data.first_reading_update + '&' +
    'latest_reading_id=' + data.latest_reading_id + '&' +
    'latest_reading=' + data.latest_reading + '&' +
    'latest_reading_update=' + data.latest_reading_update;
  var options = {
    url: URL + 'api/readingupdate/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const offsetReadings = (data) => {
  let token = localStorage.getItem('token');
  var board_id = localStorage.getItem('selectedboard')
  let creds = 'offset_percent=' + data.offset_percent + '&' +
    'board_id=' + board_id;
  var options = {
    url: URL + 'api/offset/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const getoffsetReadings = () => {
  let token = localStorage.getItem('token');
  var board_id = localStorage.getItem('selectedboard')
  var options = {
    url: URL + 'api/offset/list/?board_id=' + board_id,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getRecord = (value) => {
  let token = localStorage.getItem('token');
  var board_id = localStorage.getItem('selectedboard')
  var options = {
    url: URL + 'api/get_record/?board_id=' + board_id + '&id=' + value.record_id,
    method: 'GET',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const updateRecord = (value) => {
  let token = localStorage.getItem('token');
  var board_id = localStorage.getItem('selectedboard')
  let creds = 'board_id=' + board_id + '&' +
    'board_total_active_energy=' + value.board_total_active_energy + '&' +
    'total_active_energy=' + value.total_active_energy + '&' +
    'r_phase_current=' + value.r_phase_current + '&' +
    'y_phase_current=' + value.y_phase_current + '&' +
    'b_phase_current=' + value.b_phase_current + '&' +
    'r_phase_active_power=' + value.r_phase_active_power + '&' +
    'y_phase_active_power=' + value.y_phase_active_power + '&' +
    'b_phase_active_power=' + value.b_phase_active_power;
  var options = {
    url: URL + 'api/update_record/' + value.record_id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};
export const deleteRecord = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var options = {
    url: URL + 'api/record/delete/' + value + "/" + y,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
//------------------OPERATIONS----------------------------

export const operationSubmit = (values, startd, endd) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/operations/realtime/?' + p + '&from_date=' + startd + '&to_date=' + endd,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const operationRunningSubmit = (values, startd, endd) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/operations/runningcondition/?' + p + '&level=' + values.benchmark + '&start_date=' + startd + '&end_date=' + endd + '&params=' + values.uom,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

//------------carbon----------------------
export const CarbonNewDashboarddial = (value, fromdate, todate) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dashboarddial/carbon/emission/?board_id=' + value + '&from_date=' + fromdate + '&to_date=' + todate

    ,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const CarbonDashboardcardboxes = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/dashboard/carbon/emission/?board_id=' + value,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const carbonRealtimeSubmit = (startd, endd) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y

  var options = {
    url: URL + 'api/realtime/carbon/emission/?' + p + '&from_date=' + startd + '&to_date=' + endd,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const CarbonNewDashboardcardboxes = (board_id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/carbon/emission/new_dashboard/?board_id=' + board_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
}

export const shiftreport = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boarddata/shiftreport/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: formvalues
  };
  return axios(options)
}

export const dailyreport = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boarddata/DailyReport/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: formvalues
  };
  return axios(options)
}

export const temprhdownloadreport = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/boarddata/getlist/download/temp/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: formvalues
  };
  return axios(options)
}

export const temprhdisplayreport = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/GetTempHumi/?start_date=' + formvalues.start_date + '&end_date=' + formvalues.end_date + '&start_time=' + formvalues.start_time + '&end_time=' + formvalues.end_time + '&board_id=' + formvalues.board_id,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}

export const temprhchart = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/tempchart/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: formvalues
  };
  return axios(options)
}

export const co2chart = (formvalues) => {
  let token = localStorage.getItem('token');
  // var board_id = localStorage.getItem('selectedboard')
  //url: URL + 'api/co2_chart/?board_id=778&from_date=2024-02-10&to_date=2024-02-13',
  //url: URL + 'api/co2_chart/?board_id=778&from_date=' + formvalues.from_date + '&to_date='+ formvalues.to_date,
  //url: URL + 'api/co2_chart/?board_id=778' + formvalues.board_id + '&from_date=' + formvalues.from_date + '&to_date='+ formvalues.to_date,
  var options = {
    url: URL + 'api/co2_chart/?board_id=' + formvalues.board_id + '&from_date=' + formvalues.from_date + '&to_date='+ formvalues.to_date,
    // url: URL + 'api/co2_chart/?board_id=778&from_date=2024-02-10&to_date=2024-02-13',
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}

export const mainparenttreechart = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TREE_URL + 'api/main',
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token },
  };
  return axios(options)
}

export const parenttreechart = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TREE_URL + 'api/feeder',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: id
  };
  return axios(options)
}

export const treechart = (id) => {
  let token = localStorage.getItem('token');
  var options = {
    url: TREE_URL + 'api/sld',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: id
  };
  return axios(options)
}

export const BoardStatus = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/board/status/lists/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: formvalues
  };
  return axios(options)
}

//----------------------Heater------------------------------
export const addSheduleHeater = (data, startDate, endDate, thermo) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  let creds = 'board_id=' + y + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.startTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation;

  var options = {
    url: URL + 'api/heater/schedule/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const editScheduleHeater = (data, startDate, endDate, thermo) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var t = data.startTime.split(":")
  var u = t[0] + ":" + t[1]
  var v = data.endTime.split(":")
  var p = v[0] + ":" + v[1]
  let creds = 'board_id=' + y + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + u + '&' + 'to_time=' + u + '&' + 'updated_by=' + createdby + '&' + 'operation=' + data.operation;

  var options = {
    url: URL + 'api/heater/schedule/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const deleteScheduleHeater = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/heater/schedule/delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getScheduleHeater = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')
  let creds = 'board_id=' + y

  var options = {
    url: URL + 'api/heater/schedule/lists/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};

export const heaterController = (operation, thermo_id) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  let creds = 'thermo_id=' + thermo_id + '&' + 'operation=' + operation;

  var options = {
    url: URL + 'api/heater/power/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const powerController = (operation, board_id, circuit_breaker) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedboard')

  let creds = 'lboard_id=' + board_id + '&' + 'breakers=[' + circuit_breaker + ']&' + 'operation=' + operation;

  var options = {
    url: URL + 'api/lights/power/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const heaterUpperlimit = (limit) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')
  let creds = 'board_id=' + y + '&' + 'limit=' + limit;

  var options = {
    url: URL + 'api/heater/upperlimit/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const heaterLowerlimit = (limit) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')
  let creds = 'board_id=' + y + '&' + 'limit=' + limit;

  var options = {
    url: URL + 'api/heater/lowerlimit/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

export const heaterBypassStatus = (limit) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')
  let creds = 'board_id=' + y + '&' + 'bypass_status=' + limit;

  var options = {
    url: URL + 'api/heater/bypass/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

// ----------------------Cold Storage----------------------------------------------------------

// export const coldChart = (formvalues) => {
//   let token = localStorage.getItem('token');
//   var options = {
//   url: URL+'api/coldstoragetempchart/',
//   method: 'POST',
//   headers: {'Content-Type': 'application/json', 'Authorization': 'token ' + token}, data: formvalues
//   };
//   return axios(options)
// }


export const coldChart = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/coldstoragetempchart/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: formvalues
  };
  return axios(options)
}

export const coldstoragedownloadreport = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/download/coldstoragetemp/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: formvalues
  };
  return axios(options)
}

export const coldstoragedisplayreport = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/getcoldstoragetemplist/?start_date=' + formvalues.start_date + '&end_date=' + formvalues.end_date + '&start_time=' + formvalues.start_time + '&end_time=' + formvalues.end_time + '&board_id=' + formvalues.board_id,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}

export const coldstoragecurrentdisplay = (formvalues) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')
  var options = {
    url: URL + 'api/coldstorage/currentlimit/?board_id=' + y,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}
export const coldstoragecurrentdata = (selectedboard) => {
  let token = localStorage.getItem('token');
  //var y =localStorage.getItem('selectedboard')
  var y = selectedboard;
  var options = {
    url: URL + 'api/coldstorage/currentdata/?board_id=' + y,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}
export const addScheduleCreation = (schedule) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedcustomer')
  schedule.schedule[0].customer_id = localStorage.getItem('selectedcustomer')
  //let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.endTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id  ;

  var options = {
    url: URL + 'api/hvac/schedule/new_add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: schedule
  };
  return axios(options)
};
export const getScheduleList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  let creds = 'customer_id=' + y

  var options = {
    url: URL + 'api/hvac/schedule/new_list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateScheduleCreation = (schedule, id) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  schedule.schedule[0].customer_id = localStorage.getItem('selectedcustomer')

  //let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.endTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id  ;

  var options = {
    url: URL + 'api/hvac/schedule/new_update/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: schedule
  };
  return axios(options)
};
export const deleteScheduleCreation = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/hvac/schedule/new_delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getAssignmentList = value => {
  let token = localStorage.getItem('token');
  var x = localStorage.getItem('selectedcustomer')
  var y = localStorage.getItem('selectedboard')
  let creds = 'customer_id=' + x + '&board_id=' + y;

  var options = {
    url: URL + 'api/hvac/assignment/list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addAssignment = (assignment, from_date, to_date) => {
  let token = localStorage.getItem('token');
  let selectedboard = localStorage.getItem('selectedboard');
  var y = localStorage.getItem('selectedcustomer');
  assignment.board_id = selectedboard;
  assignment.customer_id = y;
  assignment.from_date = from_date;
  assignment.to_date = to_date;
  var options = {
    url: URL + 'api/hvac/assignment/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: assignment
  };
  return axios(options)
};
export const deleteAssignSchedule = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/hvac/assignment/delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};


// Heater Schedule
export const addHeaterScheduleCreation = (schedule) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedcustomer')
  schedule.schedule[0].customer_id = localStorage.getItem('selectedcustomer')
  //let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.endTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id  ;

  var options = {
    url: URL + 'api/heater/schedule/new_add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: schedule
  };
  return axios(options)
};
export const getHeaterScheduleList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  let creds = 'customer_id=' + y

  var options = {
    url: URL + 'api/heater/schedule/new_list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateHeaterScheduleCreation = (schedule, id) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  schedule.schedule[0].customer_id = localStorage.getItem('selectedcustomer')

  //let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.endTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id  ;

  var options = {
    url: URL + 'api/heater/schedule/new_update/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: schedule
  };
  return axios(options)
};
export const deleteHeaterScheduleCreation = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/heater/schedule/new_delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getHeaterAssignmentList = value => {
  let token = localStorage.getItem('token');
  var x = localStorage.getItem('selectedcustomer')
  var y = localStorage.getItem('selectedboard')
  let creds = 'customer_id=' + x + '&board_id=' + y;

  var options = {
    url: URL + 'api/heater/assignment/list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addHeaterAssignment = (assignment, from_date, to_date) => {
  let token = localStorage.getItem('token');
  let selectedboard = localStorage.getItem('selectedboard');
  var y = localStorage.getItem('selectedcustomer');
  assignment.board_id = selectedboard;
  assignment.customer_id = y;
  assignment.from_date = from_date;
  assignment.to_date = to_date;
  var options = {
    url: URL + 'api/heater/assignment/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: assignment
  };
  return axios(options)
};
export const deleteHeaterAssignSchedule = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/heater/assignment/delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const addHeaterThermostat = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.board_id = data.board_id;
  creds.thermo_name = data.name;
  creds.thermo_desc = data.desc;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/device/heater/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};
export const deleteHeaterThermostat = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/device/heater/delete/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getHeaterThermostat = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/device/heater/lists/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateHeaterThermostat = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.board_id = data.board_id;
  creds.thermo_name = data.name;
  creds.thermo_desc = data.desc;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/device/heater/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};

// Light Schedule
export const getCustomerInfo = value => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('selectedcustomer');
  let creds = {};
  creds.customer_id = customer_id;
  var options = {
    url: URL + 'api/customerinfo/details/?customer_id=' + customer_id,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const updateCustomerInfo = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.board_id = data.board_id;
  creds.thermo_name = data.name;
  creds.thermo_desc = data.desc;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/customerinfo/create/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: data
  };
  return axios(options)
};

export const getLightBoard = value => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('selectedcustomer');

  var p = "customer_id=" + customer_id
  var options = {
    url: URL + 'api/lights_board/list/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addLightBoard = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('selectedcustomer');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  data.customer_id = customer_id;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/lights_board/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: data
  };
  return axios(options)
};
export const updateLightBoard = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('selectedcustomer');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/lights_board/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: data
  };
  return axios(options)
};
export const deleteLightBoard = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var p = "board_id=" + y
  var options = {
    url: URL + 'api/lights_board/update/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getLightBreaker = (value) => {
  let token = localStorage.getItem('token');
  //let customer_id = localStorage.getItem('selectedcustomer');

  var p = "lboard_id=" + value
  var options = {
    url: URL + 'api/lights_breaker/list/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addLightBreaker = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('selectedcustomer');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  data.customer_id = customer_id;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/lights_breaker/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: data
  };
  return axios(options)
};
export const updateLightBreaker = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('selectedcustomer');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/lights_breaker/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: data
  };
  return axios(options)
};
export const deleteLightBreaker = (value) => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedboard')

  var options = {
    url: URL + 'api/lights_breaker/update/' + value + '/',
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const getLightScheduleList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  let creds = 'customer_id=' + y

  var options = {
    url: URL + 'api/lights_schedule/list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addLightScheduleCreation = (schedule) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  var y = localStorage.getItem('selectedcustomer')
  schedule.schedule[0].customer_id = localStorage.getItem('selectedcustomer')
  //let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.endTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id  ;

  var options = {
    url: URL + 'api/lights_schedule/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: schedule
  };
  return axios(options)
};
export const updateLightScheduleCreation = (schedule, id) => {
  let token = localStorage.getItem('token');
  let createdby = localStorage.getItem('user_id');
  schedule.schedule[0].customer_id = localStorage.getItem('selectedcustomer')

  //let creds = 'board_id=' + y + '&' + 'thermo_name=' + thermo.thermo_name + '&' + 'fan_speed=' + data.speed + '&' + 'swing_status=' + data.swing + '&' + 'temperature=' + data.temperature + '&' + 'thermo_desc=' + thermo.thermo_desc + '&' + 'from_date=' + startDate + '&' + 'to_date=' + endDate + '&' + 'from_time=' + data.startTime + '&' + 'to_time=' + data.endTime + '&' + 'created_by=' + createdby + '&' + 'operation=' + data.operation + '&' + 'equipments=' + data.equipment + '&' + 'thermo_id=' + thermo.id  ;

  var options = {
    url: URL + 'api/lights_schedule/update/' + id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': 'token ' + token }, data: schedule
  };
  return axios(options)
};
export const deleteLightScheduleCreation = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/api/lights_schedule/update/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const addHLightAssignmentForBoard = (assignment, from_date, to_date) => {
  let token = localStorage.getItem('token');
  let selectedboard = localStorage.getItem('selectedboard');
  var y = localStorage.getItem('selectedcustomer');
  assignment.lboard_id = assignment.lboard_id;
  assignment.customer_id = y;
  assignment.from_date = from_date;
  assignment.to_date = to_date;
  var options = {
    url: URL + 'api/lights_board_assign/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: assignment
  };
  return axios(options)
};
export const deleteLightAssignScheduleForBoard = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/lights_board_assign/delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getLightAssignmentListForBoard = value => {
  let token = localStorage.getItem('token');
  let creds = 'lboard_id=' + value;

  var options = {
    url: URL + 'api/lights_board_assign/list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addHLightAssignmentForCircuitBreaker = (assignment, from_date, to_date) => {
  let token = localStorage.getItem('token');
  let selectedboard = localStorage.getItem('selectedboard');
  var y = localStorage.getItem('selectedcustomer');
  assignment.lboard_id = assignment.lboard_id;
  assignment.customer_id = y;
  assignment.from_date = from_date;
  assignment.to_date = to_date;
  var options = {
    url: URL + 'api/lights_breaker_assign/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: assignment
  };
  return axios(options)
};
export const deleteLightAssignScheduleForCircuitBreaker = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/lights_breaker_assign/delete/' + value + "/",
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const getLightAssignmentListForCircuitBreaker = value => {
  let token = localStorage.getItem('token');
  let creds = 'lboard_id=' + value;

  var options = {
    url: URL + 'api/lights_breaker_assign/list/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
//-----------Grouping Chart-------------//
export const GetGroupingChart = value => {
  let token = localStorage.getItem('token');
  let creds = 'customer_id=' + value.customer_id + '&' + 'from_date=' + value.from_date + '&' + 'to_date=' + value.to_date;
  // let creds = {};
  // creds.customer_id = value.customer_id;
  // creds.from_date = value.from_date;
  // creds.to_date = value.to_date;
  var options = {
    url: URL + 'api/grouping_pie_chart/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }//,data:creds
  };
  return axios(options)
};
export const GetBarGroupingChart = value => {
  let token = localStorage.getItem('token');
  let creds = 'customer_id=' + value.customer_id + '&' + 'from_date=' + value.from_date + '&' + 'to_date=' + value.to_date+'&grouping_process_id='+value.grouping_process_id;
  // let creds = {};
  // creds.customer_id = value.customer_id;
  // creds.from_date = value.from_date;
  // creds.to_date = value.to_date;
  var options = {
    url: URL + 'api/subprocess_chart/?' + creds,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }//,data:creds
  };
  return axios(options)
};
//--------------Grouping--------------//
export const getGroupingList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')

  var p = "customer_id=" + y
  var options = {
    url: URL + 'api/grouping/list/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addGrouping = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  // let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.group_name = data.group_name;
  creds.main_meter = data.main_meter;
  var options = {
    url: URL + 'api/grouping/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};
export const deleteGrouping = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/grouping/delete/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const updateGrouping = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.group_name = data.group_name;
  creds.main_meter = data.main_meter;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/grouping/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};
//--------------ProcessGrouping----------//
export const getProcessGroupingList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  var p = "customer_id=" + y+ (value==null?"": "&group_id="+value)
  var options = {
    url: URL + 'api/grouping/process/list/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addProcessGrouping = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  // let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.grouping_id = data.grouping_id;
  creds.process_name = data.process_name;
  var options = {
    url: URL + 'api/grouping/process/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};
export const deleteProcessGrouping = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/grouping/process/delete/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const updateProcessGrouping = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.grouping_id = data.grouping_id;
  creds.process_name = data.process_name;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/grouping/process/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: creds
  };
  return axios(options)
};
//--------------SubProcessMapping-----------------//
export const getSubProcessMappingList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  var p = "customer_id=" + y+(value==null?"":"&group_process_id="+value)
  var options = {
    url: URL + 'api/grouping/subprocess/list/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const addSubProcessMapping = (data,subprocess) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  // let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.grouping_id = data.grouping_id;
  creds.process_name = data.process_name;
  data.subprocess=subprocess;
  var options = {
    url: URL + 'api/grouping/subprocess/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: data
  };
  return axios(options)
};
export const deleteSubProcessMapping = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/grouping/subprocess/delete/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};
export const updateSubProcessMapping = (data) => {
  let token = localStorage.getItem('token');
  let customer_id = localStorage.getItem('customer_id');
  let createdby = localStorage.getItem('user_id');
  let creds = {};
  creds.customer_id = data.customer_id;
  creds.grouping_id = data.grouping_id;
  creds.process_name = data.process_name;
  //let creds = 'customer_id=' + customer_id + '&' + 'board_id=' + data.board + '&' + 'thermo_name=' + data.name + '&' + 'thermo_desc=' + data.desc ;

  var options = {
    url: URL + 'api/grouping/subprocess/update/' + data.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: data
  };
  return axios(options)
};
export const triggergroupingCalc = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  var p = "customer_id=" + y
  var options = {
    url: URL + 'api/grouping_calc/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
//--------------Grouping Dropdown-------------//
export const getSupprocessNameList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  var p = "customer_id=" + y
  var options = {
    url: URL + 'api/grouping/subprocess_name/dropdown/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const getddlGroupingProcessList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  var p = "customer_id=" + y+"&grouping_id="+value
  var options = {
    url: URL + 'api/grouping/process/dropdown/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
export const getddlGroupingSubProcessList = value => {
  let token = localStorage.getItem('token');
  var y = localStorage.getItem('selectedcustomer')
  var p = "customer_id=" + y+"&grouping_process_id="+value;
  var options = {
    url: URL + 'api/grouping/subprocess/dropdown/?' + p,
    method: 'GET',
    headers: {
      'Authorization': 'token ' + token
    }
  };
  return axios(options)
};
// Sensor Interventions
export const SensorInterventionlist = (board_id) => {
  let token = localStorage.getItem('token');
  //let creds = 'board_id=' + board_id;
  let creds = {};
  creds.board_id = board_id;
  var options = {
    url: URL + 'api/co2/intervention/list/?board_id=' + board_id,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token
    },// data: creds
  };
  return axios(options)
}

export const AddSensorInterventions = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/co2/intervention/add/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const UpdateSensorInterventions = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/co2/intervention/update/' + value.id + '/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }, data: value
  };
  return axios(options)
};

export const DeleteSensorInterventions = value => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/co2/intervention/delete/' + value,
    method: 'DELETE',
    headers: { 'Authorization': 'token ' + token }
  };
  return axios(options)
};

export const co2Visualizationchart = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + '/api/visualization/co2/?board_id=' + formvalues.board_id + '&from_date=' + formvalues.from_date + '&to_date='+ formvalues.to_date,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}
export const pmVisualizationchart = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + '/api/visualization/pm/?board_id=' + formvalues.board_id + '&from_date=' + formvalues.from_date + '&to_date='+ formvalues.to_date,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}
export const tvocVisualizationchart = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + '/api/visualization/tvoc/?board_id=' + formvalues.board_id + '&from_date=' + formvalues.from_date + '&to_date='+ formvalues.to_date,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}
export const Occupancychart = (formvalues) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/occupancy/chart/?board_id=' + formvalues.board_id + '&from_date=' + formvalues.from_date + '&to_date='+ formvalues.to_date,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}
export const GetSLDConfigData = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/sld/data/?customer_id=' + value,
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
  };
  return axios(options)
}
export const SaveSLDConfigData = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/sld/config/create/',
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token },
    data:value
  };
  return axios(options)
}
export const UpdateSLDConfigData = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + 'api/sld/config/update/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token },
    data:value
  };
  return axios(options)
}

// export const GetReportSequence = (value) => {
//   let token = localStorage.getItem('token');
//   var options = {
//     url: URL + 'api/reportsequence/get/?customer_id=' + value,
//     method: 'GET',
//     headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token }
//   };
//   return axios(options)
// }

export const GetReportSequence = (customerId) => {
  var url = URL + (`api/reportsequence/get/?customer_id=`) + customerId;
  return axios.get (url, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  };

export const UpdateReportSequence = (value) => {
  let token = localStorage.getItem('token');
  var options = {
    url: URL + '/api/reportsequence/update/',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token },
    data:value
  };
  return axios(options)
}

  export const getAlerts = (value) => {
    let token = localStorage.getItem('token');
    var options = {
      url: URL + '/api/get-alerts/?customer_id=' + value,
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'token ' + token, 'Accept': 'application/json' }
    };
    return axios(options)
  }
  


