import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import * as apiService from '../services/apiService';
import TopNavDropdown from './TopDropdown';
import { Tree, TreeNode } from "react-organizational-chart";
import _ from "lodash";
import HT from '../assets/images/HT.jpeg';
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import { DeleteOutline, Visibility, ZoomInOutlined, ZoomOutMapOutlined, ZoomOutOutlined } from "@material-ui/icons";
import { Select, MenuItem, OutlinedInput } from '@material-ui/core';
import { RectPattern } from "@amcharts/amcharts4/core";
import { useEffect } from "react";
import classnames from 'classnames';
import { isUndefined } from "@syncfusion/ej2-base";
import Loader from "../services/loader";
import swal from "sweetalert";
import { func } from "prop-types";
import './Scroll.css'
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import pwr_on from '../assets/images/pwr_on.png';
import pwr_off from '../assets/images/pwr_off.png';

// var organization = {
//   "board_id": 1,
//   "board_name": "Director",
//   "child": [
//     {
//       "board_id": 2,
//       "board_name": "Board 1 ",
//       "child": [
//         {
//           "board_id": 5,
//           "board_name": "Test 1"
//         },
//         {
//           "board_id": 6,
//           "board_name": "Test 12"
//         }
//       ]
//     },
//     {
//       "board_id": 3,
//       "board_name": "Board 2",
//       "child": [
//         {
//           "board_id": 7,
//           "board_name": "Board 6",

//         },
//         {
//           "board_id": 8,
//           "board_name": "Board 6",
//         }
//       ]
//     },
//     {
//       "board_id": 4,
//       "board_name": "Board 1 ",
//       "child": [
//         {
//           "board_id": 9,
//           "board_name": "Test 1"
//         },
//         {
//           "board_id": 10,
//           "board_name": "Test 12",
//           "child": [
//             {
//               "board_id": 12,
//               "board_name": "Test 1"
//             }
//           ]
//         },
//         {
//           "board_id": 11,
//           "board_name": "Test 12"
//         }
//       ]
//     },
//   ]
// }

//const orgOriginal=orgOriginal
var orgOriginal = {
  config: [


  ]
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "inline-block",
    borderRadius: 16
  },
  expand: {
    transform: "rotate(0deg)",
    marginTop: -10,
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.short
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: "#ECECF4"
  },
  image: {
    position: "absolute",
    top: "50%", // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Center the image
    width: "10%", // Make image width larger to make half of it extend outside the card
    height: "auto",
  }

}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Organization({ organization, handleAddNode,
  handleDeleteNode, handleViewNode
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      variant="outlined"
      className={classes.root}
    >

      {/* <Card style={{ width: '214px !important', padding: '1px !important', backgroundColor: (organization.type == '1') ? 'lightgray' : (organization.type == '2') ? 'lightblue' : (organization.type == '3') ? 'lightsteelblue' : (organization.type == '4') ? 'lightgreen' : (organization.type == '5') ? 'lightyellow' : (organization.type == '6') ? 'rgb(115 175 177)' : 'lightgray', alignItems: 'center' }} > */}
      <Card style={{
        width: '300px',
        height: '120px',
        padding: '8px',
        backgroundColor: (organization.alertColor == '1') ? 'lightgray' : (organization.alertColor == '2') ? 'lightblue' : (organization.alertColor == '3') ? 'lightsteelblue' : (organization.alertColor == '4') ? 'lightgreen' : (organization.alertColor == '5') ? 'lightyellow' : (organization.alertColor == '6') ? 'rgb(115 175 177)' : '',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }} >
        {/* <img src={HT} width={'10px'}height={'10px'} alt="Centered Image" className={classes.image} /> */}
        {/* Header section */}
        <div style={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',  // Center the title  
          width: '100%',
          marginBottom: '8px',
          minHeight: '40px'
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',  // Center the power icon and text
            width: '100%',
            paddingRight: '40px'  // Add padding to prevent text overlap with button  
          }}>
            <PowerSettingsNewIcon style={{
              color: (organization.pwrStatus == '1' ? '#17d91d' : 'red'),
              marginRight: '4px',
            }} />
            <Typography style={{
              fontSize: '1rem',
              fontWeight: '500',
              textAlign: 'center',  // Center the text
              wordBreak: 'break-word'
            }}>
              {organization?.board_name}
            </Typography>
          </div>
          <IconButton
            size="small"
            onClick={handleClick}
            style={{
              position: 'absolute', right: '8px', top: '50%',
              transform: 'translateY(-50%)'
            }}  // Position the menu icon to the right  
          >
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </div>
        <Row style={{ margin: '0' }}>
          <Col xs="6" style={{ padding: '0' }}>
            <Typography style={{
              fontSize: '0.85rem',
              lineHeight: '1.4',
              textAlign: 'left',
              paddingLeft: '12px'  // Add some padding from the left  
            }}>
              Avg. VL-N: {organization?.average_neutral_voltage}<br />
              Avg. VL-L: {organization?.average_phase_voltage}<br />
              Avg. Amp: {organization?.average_current}
            </Typography>
          </Col>
          <Col xs="6" style={{ padding: '0' }}>
            <Typography style={{
              fontSize: '0.85rem',
              lineHeight: '1.4',
              textAlign: 'left',
              paddingLeft: '12px'  // Add some padding from the left  
            }}>
              Avg. PF: {organization?.total_power_factor}<br />
              Total kW: {organization?.total_active_power}<br />
              Total kVA: {organization?.total_apparent_power}
            </Typography>
          </Col>
        </Row>
      </Card>
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={() => {
          handleClose()
          handleAddNode(organization?.board_id, {
            "board_id": 13,
            "board_name": "New Board"
          });
        }}>
          <ListItemIcon style={{ minWidth: '24px' }}>
            <PersonIcon color="primary" />  {/* Changed from 'danger' to 'primary' */}
          </ListItemIcon>
          <ListItemText style={{ marginLeft: '8px' }} primary="Add" />
        </MenuItem>
        <MenuItem onClick={() => {
          handleDeleteNode(organization?.board_id)
          handleClose()
        }}>
          <ListItemIcon style={{ minWidth: '24px' }}>
            <DeleteOutline color="error" />  {/* Changed from 'secondary' to 'error' */}
          </ListItemIcon>
          <ListItemText style={{ marginLeft: '8px' }} primary="Delete" />
        </MenuItem>
        <MenuItem onClick={() => {
          handleViewNode(organization)
          handleClose()
        }}>
          <ListItemIcon style={{ minWidth: '24px' }}>
            <Visibility color="primary" />  {/* Changed from 'secondary' to 'primary' */}
          </ListItemIcon>
          <ListItemText style={{ marginLeft: '8px' }} primary="View" />
        </MenuItem>
      </Menu>

    </Card>
  );
}

function Node({ organization, parent, handleAddNode,
  handleDeleteNode, handleViewNode }) {

  const T = parent
    ? TreeNode
    : (props) => (
      <Tree
        {...props}
        lineWidth={"2px"}
        lineColor={"#bbc"}
        lineBorderRadius={"12px"}
      >
        {props.children}
      </Tree>
    );
  return <T
    label={
      <Organization
        organization={organization}
        handleAddNode={handleAddNode}
        handleDeleteNode={handleDeleteNode}
        handleViewNode={handleViewNode}
      // setOrganization={setOrganization}

      // org={org} setOrg={setOrg}
      />
    }
  >
    {_.map(organization?.child, (item) => (
      <Node
        key={item.board_id}
        organization={item}
        parent={organization}
        handleAddNode={handleAddNode}
        handleDeleteNode={handleDeleteNode}
        handleViewNode={handleViewNode}
      />
    ))}
  </T>
}
const theme = createMuiTheme({
  // palette: {
  //   background: "#ECECF4"
  // },
  fontFamily: "Roboto, sans-serif"
});
// const orgOriginal: OrgOriginal = {
//   config: []
// };
function OrgChart() {
  const [selectedcustomer, setSelectedCustomer] = React.useState(localStorage.getItem('selectedcustomer'))
  const [selectedboard, setSelectedboard] = React.useState('')
  const [organization, setOrganization] = React.useState(orgOriginal.config)
  //const [organization, setOrganization] = useState<orgOriginal["config"]>(orgOriginal.config)
  const [boardlist, setBoardlist] = React.useState([])
  const [openModal, setopenModal] = React.useState(false)
  const [openModalnew, setopenModalnew] = React.useState(false)
  const [openModalCard, setopenModalCard] = React.useState(false);
  const [isNewConfig, setisNewConfig] = React.useState(true)
  const [parentNodeId, setparentNodeId] = React.useState('');
  const [listIndexId, setlistIndexId] = React.useState('');
  const [selectedOption, setselectedOption] = React.useState('');
  const [selectedType, setselectedType] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [cardType, setcardType] = React.useState('');
  const [alertColor, setalertColor] = React.useState('');
  const [cardName, setcardName] = React.useState('');
  const [cardAvgVLN, setcardAvgVLN] = React.useState('');
  const [cardAvgVLL, setcardAvgVLL] = React.useState('');
  const [cardAvgAMP, setcardAvgAMP] = React.useState('');
  const [cardAvgPF, setcardAvgPF] = React.useState('');
  const [cardAvgKW, setcardAvgKW] = React.useState('');
  const [cardAvgKVA, setcardAvgKVA] = React.useState('');
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [sldName, setSldName] = React.useState('');
  const [renameModalOpen, setRenameModalOpen] = React.useState(false);
  const [newSldName, setNewSldName] = React.useState('');
  const containerRef = React.useRef(null);
  let isDragging = false;
  let startX, startY, scrollLeft, scrollTop;

  const startDrag = (e) => {
    if (!containerRef.current) return;  // Add this check  
    isDragging = true;
    startX = e.pageX - containerRef.current.offsetLeft;
    startY = e.pageY - containerRef.current.offsetTop;
    scrollLeft = containerRef.current.scrollLeft;
    scrollTop = containerRef.current.scrollTop;
    containerRef.current.style.cursor = "grabbing";
  };

  React.useEffect(() => {
    // Cleanup function  
    return () => {
      isDragging = false;
      startX = null;
      startY = null;
      scrollLeft = null;
      scrollTop = null;
    };
  }, []);

  const endDrag = () => {
    isDragging = false;
    if (containerRef.current) {  // Add this check  
      containerRef.current.style.cursor = "grab";
    }
  };
  const handleDrag = (e) => {
    if (!isDragging || !containerRef.current) return;  // Add this check  
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const y = e.pageY - containerRef.current.offsetTop;
    containerRef.current.scrollLeft = scrollLeft - (x - startX);
    containerRef.current.scrollTop = scrollTop - (y - startY);
  };
  const handleZoomIn = () => setZoomLevel((prev) => Math.min(prev + 0.1, 2));
  const handleZoomOut = () => setZoomLevel((prev) => Math.max(prev - 0.1, 0.5));
  // const [orgTemp, setOrgTemp] = React.useState(orgOriginal)
  useEffect(() => {
    if (selectedcustomer) {
      setLoader(true);
      Promise.all([
        getchartData(selectedcustomer),
        getBoardData(selectedcustomer)
      ])
        .then(() => {
          setLoader(false);
        })
        .catch(error => {
          setLoader(false);
          apiService.ErrorMessage(error);
        });
    } else {
      setOrganization([]);
      setBoardlist([]);
    }
  }, [selectedcustomer]);

  const getchartData = (selectedcustomer) => {
    return new Promise((resolve, reject) => {
      apiService.GetSLDConfigData(selectedcustomer).then(response => {
        if (isUndefined(response.data.message)) {
          setOrganization(response.data.config);
          if (response.data.config.length > 0) {
            setlistIndexId(0);
            setisNewConfig(false);
          } else {
            setisNewConfig(true);
          }
        } else {
          setisNewConfig(true);
          setOrganization([]);
        }
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  };
  const getBoardData = (selectedcustomer) => {
    return new Promise((resolve, reject) => {
      apiService.Boarddropdowndata(selectedcustomer).then(response => {
        setBoardlist(response.data);
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  }
  const customerhandler = (customerId) => {
    // Reset all states to initial values 
    setSelectedCustomer(customerId);
    localStorage.setItem('selectedcustomer', customerId);
    setOrganization([]);
    setSelectedboard('');
    setBoardlist([]);
    setlistIndexId(0);
    setisNewConfig(true);
    setLoader(true);

    // Force a refresh of the data  
    if (customerId) {
      setLoader(true);
      Promise.all([
        getchartData(customerId),
        getBoardData(customerId)
      ])
        .then(() => {
          setLoader(false);
        })
        .catch(error => {
          setLoader(false);
          apiService.ErrorMessage(error);
        });
    }
  };

  const boardhandler = (board_id) => {
    setSelectedboard(board_id)
    localStorage.setItem('selectedboard', board_id)
  }
  function deleteNodeByIndex(lIndex) {
    setopenModal(true);
    setlistIndexId(lIndex);
  }
  const handleViewNode = (board_id) => {
    setopenModalCard(true);
    //var selectedOrg=organization[listIndexId];
    //var selectedNode = selectedOrg.filter(child => child.board_id == board_id);
    var selectedNode = board_id;
    setcardName(selectedNode.board_name)
    setcardAvgVLN(selectedNode.average_neutral_voltage)
    setcardAvgVLL(selectedNode.average_phase_voltage)
    setcardAvgAMP(selectedNode.average_current)
    setcardAvgPF(selectedNode.total_power_factor)
    setcardAvgKW(selectedNode.total_active_power)
    setcardAvgKVA(selectedNode.total_apparent_power)
    setcardType(selectedNode.type)
    setalertColor(selectedNode.alertColor)
  }
  const handleDeleteNode = (board_id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Node!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          const orgTemp = [...organization]
          function deleteNodeById(obj, board_id) {
            if (!obj || typeof obj !== 'object') {
              return null;
            }

            if (obj.board_id === board_id) {
              return null;
            }

            if (Array.isArray(obj.child)) {
              obj.child = obj.child?.map(child => deleteNodeById(child, board_id));
              obj.child = obj.child.filter(child => child !== null);
            }
            return obj;
          }

          deleteNodeById(orgTemp[listIndexId], board_id)
          var newOrg = orgTemp;
          newOrg[listIndexId] = orgTemp[listIndexId];
          setOrganization(newOrg)
        } else {
        }
      });

  }
  const deleteSLDByIndex = (indexId) => {
    swal({
      title: "Are you sure you want to delete this SLD?",
      text: "Once deleted, you will not be able to recover this SLD! To save the changes, click the SAVE button.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          setOrganization(prevOrg => {
            const newOrg = prevOrg.filter((_, index) => index !== indexId);
            // If we're deleting the currently selected SLD, select the previous one or the first one  
            if (listIndexId >= newOrg.length) {
              setlistIndexId(Math.max(0, newOrg.length - 1));
            }
            return newOrg;
          });
        }
      });
  };

  function OpenAddModel(parentId, lIndex) {
    setopenModal(true);
    setparentNodeId(parentId);
    //setlistIndexId(lIndex);
  }
  function SaveOrgChart() {
    var Temp = {
      "sld": [{
        "customer_id": selectedcustomer,
        "config": []
      }]
    };
    Temp.sld[0].customer_id = selectedcustomer;
    Temp.sld[0].config = organization;
    if (isNewConfig) {
      setLoader(true);
      apiService.SaveSLDConfigData(Temp).then(response => {
        //setOrganization(response.data)
        apiService.Alerts("SLD Design Created Successfully")
        getchartData(selectedcustomer)
        setLoader(false);
      }).catch(error => {
        setLoader(false);
        apiService.ErrorMessage(error)
      })
    } else {
      setLoader(true);
      apiService.UpdateSLDConfigData(Temp).then(response => {
        //setOrganization(response.data)
        apiService.Alerts("SLD Design Updated Successfully")
        getchartData(selectedcustomer)
        setLoader(false);
      }).catch(error => {
        setLoader(false);
        apiService.ErrorMessage(error)
      })
    }
  }
  function GetBoardName(newNodeId) {
    return boardlist.filter(s => s.id == newNodeId)[0]['itemName']
  }
  function handleAddNode(parentId, newNode, newNodeId) {
    function findAndAddNode(organization, parentId, newNode, newNodeId) {
      if (organization.board_id === parentId) {
        if (!organization.child) {
          organization.child = [];
        }
        organization.child.push({ "board_id": newNode, "board_name": GetBoardName(newNode), "type": selectedType });
        return true;
      }
      if (organization.child) {
        for (let i = 0; i < organization.child.length; i++) {
          if (findAndAddNode(organization.child[i], parentId, newNode, newNodeId)) {
            return true;
          }
        }
      }
      return false;
    }
    findAndAddNode(organization[listIndexId], parentId, newNode, newNodeId);
    const orgTemp = { ...organization }
    var newOrg = organization;
    newOrg[listIndexId] = orgTemp[listIndexId];
    setOrganization(newOrg)
    // setOrgTemp(orgTemp)
  }
  function handleAddNewNode(newNode) {
    var newOrg = organization;
    //newOrg[listIndexId]=orgTemp;
    //console.log(newOrg)
    newOrg.push({ "board_id": newNode, "board_name": GetBoardName(newNode), "type": selectedType, "sld_name": sldName });
    var finOrg = organization;
    finOrg[0] = newOrg[0];
    //findAndAddNode(organization[listIndexId], parentId, newNode, newNodeId);
    //const orgTemp = { ...organization }
    setOrganization(finOrg)
    setlistIndexId(newOrg.length - 1);
    // setOrgTemp(orgTemp)
  }
  function toggle(lIndex) {
    setlistIndexId(lIndex);
  }
  return (
    <>
      <div className="content">
        {
          loader && <Loader />
        }
        <ThemeProvider theme={theme}>
          <Box bgcolor="background">
            <Row>
              <Col md="12">
                <TopNavDropdown
                  customerhandler={customerhandler}
                  selectedcustomer={selectedcustomer}
                  boardhandler={boardhandler}
                  selectedboard={selectedboard}
                  showProcessDropdown={true}
                />
              </Col>
              <Col md="12">
                {/* <div>
                  <svg width="20" height="20">
                    <rect width="30" height="30" fill="lightgray" />
                  </svg> <span style={{ fontWeight: 500 }}>- HT&nbsp;</span>
                  <svg width="20" height="20">
                    <rect width="30" height="30" fill="lightblue" />
                  </svg>  <span style={{ fontWeight: 500 }}>- LT&nbsp;</span>
                  <svg width="20" height="20">
                    <rect width="30" height="30" fill="lightsteelblue" />
                  </svg> <span style={{ fontWeight: 500 }}>- Transformer &nbsp;</span>
                  <svg width="20" height="20">
                    <rect width="30" height="30" fill="lightgreen" />
                  </svg> <span style={{ fontWeight: 500 }}> - Main &nbsp;</span>
                  <svg width="20" height="20">
                    <rect width="30" height="30" fill="lightyellow" />
                  </svg>  <span style={{ fontWeight: 500 }}>- Sub-main&nbsp;</span>
                  <svg width="20" height="20">
                    <rect width="30" height="30" fill="rgb(115 175 177)" />
                  </svg>  <span style={{ fontWeight: 500 }}>- Process&nbsp;</span>
                </div> */}
                <Button color="success pull-right" onClick={() => { SaveOrgChart() }} >Save</Button>
                {organization.length == 0 && <Button color="info pull-right" onClick={() => { setopenModalnew(true) }} >New</Button>}
              </Col>
              {/* {organization.length > 0 && */}
              <>
                <Col md="12">
                  <Card>
                    <Nav tabs>
                      {organization.map((value, index) => (
                        <NavItem key={`nav-item-${index}`}>
                          <NavLink
                            key={`nav-link-${index}`}
                            className={classnames({ active: listIndexId === index })}
                            onClick={() => { toggle(index); }}
                            style={{ fontWeight: 500 }}
                          >
                            {value.sld_name || `SLD - ${index + 1}`}
                          </NavLink>
                        </NavItem>
                      ))}
                      <NavItem key="nav-item-add">
                        <NavLink onClick={() => { setopenModalnew(true) }}>
                          +
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={listIndexId}>
                      {Array.isArray(organization) && organization.map((value, index) => (
                        <TabPane key={`tab-pane-${index}`} tabId={index}>
                          <Col md="12" key={`col-controls-${index}`}>
                            <div style={{ display: "flex", justifyContent: "flex-end", padding: "8px" }}>
                              <IconButton onClick={handleZoomIn} size="small"><ZoomInOutlined></ZoomInOutlined></IconButton>
                              <IconButton onClick={handleZoomOut} size="small"><ZoomOutOutlined></ZoomOutOutlined></IconButton>
                              <Button
                                color="primary"
                                style={{ marginRight: "8px" }}
                                onClick={() => {
                                  setNewSldName(value.sld_name || `SLD - ${index + 1}`);
                                  setRenameModalOpen(true);
                                }}
                              >
                                Rename
                              </Button>
                              <Button color="danger pull-right" onClick={() => { deleteSLDByIndex(index) }}>Delete</Button>
                            </div>
                          </Col>
                          <div
                            key={`container-${index}`}
                            ref={containerRef}
                            onMouseDown={startDrag}
                            onMouseUp={endDrag}
                            onMouseLeave={endDrag}
                            onMouseMove={handleDrag}
                            style={{
                              width: "100%",
                              height: "500px",
                              overflow: "auto",
                              border: "1px solid #ddd",
                              padding: "10px"
                            }}
                          >
                            <div
                              key={`zoom-container-${index}`}
                              style={{
                                transform: `scale(${zoomLevel})`,
                                transformOrigin: "top left",
                                transition: "transform 0.2s ease-in-out",
                                display: "inline-block"
                              }}
                            >
                              <Node
                                key={`node-${index}`}
                                organization={value}
                                handleAddNode={OpenAddModel}
                                handleDeleteNode={handleDeleteNode}
                                handleViewNode={handleViewNode}
                              />
                            </div>
                          </div>
                        </TabPane>
                      ))}
                    </TabContent>

                  </Card>
                </Col>
              </>
              {/* } */}
            </Row>
          </Box>
        </ThemeProvider>
        <MDBContainer>
          <MDBModal
            isOpen={openModal}
            toggle={() => setopenModal(!openModal)}
            position="center"
            data-mdb-backdrop="static"
            size="md"
          >
            <MDBModalHeader style={{ display: "unset", backgroundColor: 'white', padding: '1rem' }}>
              <button
                type="button"
                onClick={() => setopenModal(!openModal)}
                className="close text-right mr-4 p-0"
                aria-label="Close"
              >
                <span style={{ fontSize: '30px', color: 'red' }} aria-hidden="true">×</span>
              </button>
            </MDBModalHeader>
            <MDBModalBody style={{ backgroundColor: 'white', padding: '1rem' }}>
              <Box style={{
                padding: "0px 16px",
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '100%'
              }}>
                {/* First Dropdown */}
                <div>
                  <InputLabel id="type-dropdown-label" style={{ marginBottom: '8px' }}>
                    Select Type
                  </InputLabel>
                  <Select
                    labelId="type-dropdown-label"
                    value={selectedType}
                    onChange={(e) => setselectedType(e.target.value)}
                    style={{ width: '100%' }}
                    input={<OutlinedInput />}
                  >
                    <MenuItem value="1">HT</MenuItem>
                    <MenuItem value="2">LT</MenuItem>
                    <MenuItem value="3">Transformer</MenuItem>
                    <MenuItem value="4">Main</MenuItem>
                    <MenuItem value="5">Sub-main</MenuItem>
                    <MenuItem value="6">Process</MenuItem>
                  </Select>
                </div>

                {/* Second Dropdown */}
                <div>
                  <InputLabel id="process-dropdown-label" style={{ marginBottom: '8px' }}>
                    Select Process
                  </InputLabel>
                  <Select
                    labelId="process-dropdown-label"
                    value={selectedOption}
                    onChange={(e) => setselectedOption(e.target.value)}
                    style={{ width: '100%' }}
                    input={<OutlinedInput />}
                  >
                    {boardlist.map(option => (
                      <MenuItem key={`menu-item-${option.id}`} value={option.id}>
                        {option.itemName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Box>
            </MDBModalBody>
            <MDBModalFooter style={{ backgroundColor: 'white', padding: '1rem', justifyContent: 'center' }}>
              <MDBBtn
                disabled={selectedOption == '' || selectedType == ''}
                color="success"
                onClick={() => { setopenModal(false); handleAddNode(parentNodeId, selectedOption); }}
              >
                Map
              </MDBBtn>
              <MDBBtn color="secondary" onClick={() => { setopenModal(false) }}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={openModalnew}
            toggle={() => setopenModalnew(!openModalnew)}
            position="center"
            data-mdb-backdrop="static"
            size="md"
          >
            <MDBModalHeader style={{ display: "unset", backgroundColor: 'white', padding: '1rem' }}>
              <button
                type="button"
                onClick={() => setopenModalnew(!openModalnew)}
                className="close text-right mr-4 p-0"
                aria-label="Close"
              >
                <span style={{ fontSize: '30px', color: 'red' }} aria-hidden="true">×</span>
              </button>
            </MDBModalHeader>
            <MDBModalBody style={{ backgroundColor: 'white', padding: '1rem' }}>
              <Box style={{
                padding: "0px 16px",
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '100%'
              }}>
                {/* Add SLD Name Input */}
                <div>
                  <InputLabel style={{ marginBottom: '8px' }}>
                    SLD Name
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    value={sldName}
                    onChange={(e) => setSldName(e.target.value)}
                    placeholder="Enter SLD Name"
                  />
                </div>
                {/* First Dropdown */}
                <div>
                  <InputLabel id="type-dropdown-label" style={{ marginBottom: '8px' }}>
                    Select Type
                  </InputLabel>
                  <Select
                    labelId="type-dropdown-label"
                    value={selectedType}
                    onChange={(e) => setselectedType(e.target.value)}
                    style={{ width: '100%' }}
                    input={<OutlinedInput />}
                  >
                    <MenuItem value="1">HT</MenuItem>
                    <MenuItem value="2">LT</MenuItem>
                    <MenuItem value="3">Transformer</MenuItem>
                    <MenuItem value="4">Main</MenuItem>
                    <MenuItem value="5">Sub-main</MenuItem>
                    <MenuItem value="6">Process</MenuItem>
                  </Select>
                </div>

                {/* Second Dropdown */}
                <div>
                  <InputLabel id="process-dropdown-label" style={{ marginBottom: '8px' }}>
                    Select Process
                  </InputLabel>
                  <Select
                    labelId="process-dropdown-label"
                    value={selectedOption}
                    onChange={(e) => setselectedOption(e.target.value)}
                    style={{ width: '100%' }}
                    input={<OutlinedInput />}
                  >
                    {boardlist.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.itemName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Box>
            </MDBModalBody>
            <MDBModalFooter style={{ backgroundColor: 'white', padding: '1rem', justifyContent: 'center' }}>
              <MDBBtn
                disabled={selectedOption == '' || selectedType == ''}
                color="success"
                onClick={() => { setopenModalnew(false); handleAddNewNode(selectedOption); }}
              >
                Map
              </MDBBtn>
              <MDBBtn color="secondary" onClick={() => { setopenModalnew(false) }}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
        <MDBContainer>
          <MDBModal
            isOpen={openModalCard}
            toggle={() => setopenModalCard(!openModalCard)}
            position="center"
            data-mdb-backdrop="static"
          >
            <MDBModalHeader style={{ display: "unset", backgroundColor: (alertColor != '' && alertColor != undefined) ? alertColor : '#fff' }}>
              <h6 style={{ fontWeight: 400 }}><b>{cardName} - ({(cardType == '1') ? 'HT' : (cardType == '2') ? 'LT' : (cardType == '3') ? 'Transformer' : (cardType == '4') ? 'Main' : (cardType == '5') ? 'Sub-main' : (cardType == '6') ? 'Process' : ''})</b></h6>
              {/* <button type="button" onClick={() => { setopenModalCard(false) }} className="close text-right mr-4 p-0" aria-label="Close">
                <span style={{ fontSize: '50px', color: 'red' }} aria-hidden="true">×</span>
              </button> */}
            </MDBModalHeader>
            <MDBModalBody style={{ backgroundColor: (alertColor != '' && alertColor != undefined) ? alertColor : '#fff' }}>
              <Row className="m-2" style={{ height: "200px", backgroundColor: (alertColor != '' && alertColor != undefined) ? alertColor : '#fff' }}>
                <Col sm="12 p-0" className="m-2">
                  <Typography style={{ marginLeft: '16px', fontWeight: 400, marginRight: "8px", fontSize: '1.5rem', textAlign: 'left' }}>
                    Avg. VL-N: <b> {cardAvgVLN} </b> <br></br>
                    Avg. VL-L: <b> {cardAvgVLL} </b><br></br>
                    Avg. Ampere: <b> {cardAvgAMP}</b> <br></br>
                    Avg. PF: <b> {cardAvgPF} </b> <br></br>
                    Total kW: <b> {cardAvgKW} </b> <br></br>
                    Total kVA: <b> {cardAvgKVA} </b>
                  </Typography>
                </Col>
              </Row>
            </MDBModalBody>
            <MDBModalFooter style={{ backgroundColor: (alertColor != '' && alertColor != undefined) ? alertColor : '#fff' }}>
              {/* <MDBBtn disabled={selectedOption == '' || selectedType == ''} color="success" onClick={() => { setopenModalnew(false); handleAddNewNode(selectedOption); }} >Map</MDBBtn> */}
              <MDBBtn color="secondary" onClick={() => { setopenModalCard(false) }} >Close</MDBBtn>
              {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            isOpen={renameModalOpen}
            toggle={() => setRenameModalOpen(!renameModalOpen)}
            position="center"
            data-mdb-backdrop="static"
            size="sm"
          >
            <MDBModalHeader style={{ display: "unset", backgroundColor: 'white', padding: '1rem' }}>
              <h5 style={{ marginBottom: '0' }}>Rename SLD</h5>
              <button
                type="button"
                onClick={() => setRenameModalOpen(false)}
                className="close text-right mr-4 p-0"
                aria-label="Close"
              >
                <span style={{ fontSize: '30px', color: 'red' }} aria-hidden="true">×</span>
              </button>
            </MDBModalHeader>
            <MDBModalBody style={{ backgroundColor: 'white', padding: '1rem' }}>
              <Box style={{
                padding: "0px 16px",
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '100%'
              }}>
                <div>
                  <InputLabel style={{ marginBottom: '8px' }}>
                    SLD Name
                  </InputLabel>
                  <OutlinedInput
                    fullWidth
                    value={newSldName}
                    onChange={(e) => setNewSldName(e.target.value)}
                    placeholder="Enter SLD Name"
                  />
                </div>
              </Box>
            </MDBModalBody>
            <MDBModalFooter style={{ backgroundColor: 'white', padding: '1rem', justifyContent: 'center' }}>
              <MDBBtn
                disabled={!newSldName.trim()}
                color="success"
                onClick={() => {
                  // Update the SLD name  
                  const updatedOrg = [...organization];
                  updatedOrg[listIndexId] = {
                    ...updatedOrg[listIndexId],
                    sld_name: newSldName.trim()
                  };
                  setOrganization(updatedOrg);

                  // Call the update API  
                  const updateData = {
                    "sld": [{
                      "customer_id": selectedcustomer,
                      "config": updatedOrg
                    }]
                  };

                  setLoader(true);
                  apiService.UpdateSLDConfigData(updateData).then(response => {
                    apiService.Alerts("SLD Name Updated Successfully");
                    getchartData(selectedcustomer);
                    setLoader(false);
                    setRenameModalOpen(false);
                  }).catch(error => {
                    setLoader(false);
                    apiService.ErrorMessage(error);
                  });
                }}
              >
                Update
              </MDBBtn>
              <MDBBtn color="secondary" onClick={() => {
                setRenameModalOpen(false);
                setNewSldName('');
              }}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </div>
    </>

  );
}

export default OrgChart;
